import { atom } from "jotai";

export const MODAL_TYPES = {
  ALERT: "alert",
  CONFIRM: "confirm",
  CANCEL_CONTRACT: "cancelContract",
} as const;

export type ModalType = (typeof MODAL_TYPES)[keyof typeof MODAL_TYPES];

export interface ModalState {
  status: boolean;
  type: ModalType | null;
  data?: Record<string, any>;
}

const initialModalState: Record<string, ModalState> = {
  [MODAL_TYPES.ALERT]: {
    status: false,
    type: null,
    data: {},
  },
  [MODAL_TYPES.CONFIRM]: {
    status: false,
    type: null,
    data: {},
  },
  [MODAL_TYPES.CANCEL_CONTRACT]: {
    status: false,
    type: null,
    data: {},
  },
};

export const modalAtom = atom(initialModalState);
