import React from 'react';
import {CloseButton, CloseButtonContainer, ModalBackground, ModalBoxContainer, ModalMessageContainer, ModalContentContainer} from './Modals.styled';

export interface IModalProps {
  message: string | string[]; // 배열 또는 문자열로 정의
  onClose: () => void; // 모달 닫기 함수를 전달받는 prop
}

export interface IFieldMessage {
  field: string;
  messages: string;
}

const Modals = ({ message, onClose }: IModalProps) => {
  return (
    <ModalBackground>
      <ModalBoxContainer>
        <ModalContentContainer>
          <ModalMessageContainer>
            {Array.isArray(message) ? (
              message.map((msg, index) => <p key={index}>{msg}</p>)
            ) : (
              // HTML 태그를 포함한 문자열 처리
              <div dangerouslySetInnerHTML={{ __html: message }} />
            )}
          </ModalMessageContainer>
          <CloseButtonContainer>
            <CloseButton onClick={onClose}>OK</CloseButton>
          </CloseButtonContainer>
        </ModalContentContainer>
      </ModalBoxContainer>
    </ModalBackground>
  );
};

export default Modals;
