import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const NavBarContainer = styled.nav`
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-bottom: 0.1rem solid var(--gray-300);
  height: 6rem;
  padding: 1.2rem 8rem;
  color: var(--gray-900);
  > .arrow {
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background-image: url("/svg/arrow_left.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
  }
`;

const NavBack = () => {
  const navigate = useNavigate();

  return (
    <NavBarContainer>
      <button type="button" className="arrow" onClick={() => navigate(-1)} />
    </NavBarContainer>
  );
};

export default NavBack;
