import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const CelebListContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 128rem;
  margin: 0 auto;
`;

const CelebListContent = () => {
  return (
    <CelebListContainer>
      <Outlet />
    </CelebListContainer>
  );
};

export default CelebListContent;
