import React, { useState } from "react";
import styled from "styled-components";
import StyledButton from "../button/StyledButton";
import StyledCheckbox from "../common/StyledCheckbox";
import ImageContainer from "../common/ImageContainer";
import useStockCartList from "../../hooks/useStockCartList";

const StockCartContainer = styled.div`
  width: 42rem;
  border: 0.1rem;
  border-radius: 0.4rem;
  padding: 2rem;
  background: var(--white);
  box-shadow: 0rem 0rem 2rem 0rem rgba(71, 71, 71, 0.2);
  backdrop-filter: blur(0.2rem);
  position: absolute;
  top: 4.8rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const CloseModal = styled.div`
  width: 4rem;
  height: 4rem;
  margin-left: auto;
  cursor: pointer;
  background-image: url("/svg/modal_close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const StockItemList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

const StockItem = styled.li`
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  border-top: 0.1rem solid var(--gray-300);
  &:first-child {
    border: none;
    padding-top: 0;
  }
  > .stock {
    width: 100%;
    height: 12rem;
    display: flex;
    align-items: center;
    > .stock-id {
      color: var(--gray-900);
      ${({ theme }) => theme.font.body1};
      ${({ theme }) => theme.font.regular};
      margin-left: auto;
      margin-right: 1.2rem;
      width: 12rem;
      text-align: right;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`;

interface StockCartProps {
  celebId: string;
  closeFn: (value: boolean) => void;
}

const StockCart = ({ celebId, closeFn }: StockCartProps) => {
  const { stockList, removeStock, removeCart } = useStockCartList(celebId);
  const [selectedStocks, setSelectedStocks] = useState<Set<string>>(new Set());

  const handleCheckboxChange = (stockId: string) => {
    setSelectedStocks((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(stockId)) {
        newSet.delete(stockId);
      } else {
        newSet.add(stockId);
      }
      return newSet;
    });
  };

  const handleDeleteSelected = () => {
    selectedStocks.forEach((stockId) => removeStock(stockId));
    setSelectedStocks(new Set());
  };

  return (
    <StockCartContainer>
      <CloseModal onClick={() => closeFn(false)} />
      <StockItemList>
        {stockList.length ? (
          stockList.map((stock) => {
            return (
              <StockItem key={stock.id}>
                <div className="stock">
                  <StyledCheckbox
                    label=""
                    onChange={() => handleCheckboxChange(stock.photoId)}
                    checked={selectedStocks.has(stock.photoId)}
                  />
                  <div className="stock-id">{stock.photoId}</div>
                  <ImageContainer width={10.6} height={12}>
                    <img src={stock.imgUrl} alt="selected-stock" />
                  </ImageContainer>
                </div>
              </StockItem>
            );
          })
        ) : (
          <>Empty</>
        )}
      </StockItemList>
      <ButtonContainer>
        <StyledButton
          size="sm"
          color="white"
          label="Delete selected"
          onClick={() => handleDeleteSelected()}
        />
        <StyledButton
          size="sm"
          color="white"
          label="Delete all"
          onClick={() => removeCart()}
        />
      </ButtonContainer>
    </StockCartContainer>
  );
};

export default StockCart;
