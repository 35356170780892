import { useAtom } from "jotai";
import { selectedImageListAtom } from "../atoms/contract";
import { CelebStockData } from "../components/interfaces/celeb.interfaces";

const useStockCartList = (id: string | null | undefined) => {
  const [selectedImageList, setSelectedImageList] = useAtom(
    selectedImageListAtom
  );

  const stockList: CelebStockData[] =
    (id && selectedImageList.find((item) => item.id === id)?.stockList) || [];

  const addStock = (item: CelebStockData) => {
    if (!id) return;

    setSelectedImageList((prevState) => {
      const existingCart = prevState.find((cart) => cart.id === id);

      if (existingCart) {
        return prevState.map((cart) =>
          cart.id === id
            ? {
                ...cart,
                stockList: cart.stockList.some(
                  (i) => i.photoId === item.photoId
                )
                  ? cart.stockList
                  : [...cart.stockList, item],
              }
            : cart
        );
      } else {
        return [...prevState, { id, stockList: [item] }];
      }
    });
  };

  const removeStock = (photoId: string) => {
    if (!id) return;

    setSelectedImageList((prevState) =>
      prevState.map((cart) =>
        cart.id === id
          ? {
              ...cart,
              stockList: cart.stockList.filter(
                (stock) => stock.photoId !== photoId
              ),
            }
          : cart
      )
    );
  };

  const removeCart = () => {
    if (!id) return;

    setSelectedImageList((prevState) =>
      prevState.filter((cart) => cart.id !== id)
    );
  };

  const isEmpty = (): boolean => {
    return stockList.length === 0;
  };

  return { stockList, addStock, removeStock, removeCart, isEmpty };
};

export default useStockCartList;
