import { useMutation } from "@tanstack/react-query";
import React, { useState, KeyboardEvent } from "react";
import styled from "styled-components";
import { postComment } from "../../api/contract";
import {
  PostCommentForm,
  PostCommentSuccessResponse,
} from "../interfaces/contract.interface";
import { AxiosError } from "axios";

const Wrapper = styled.form`
  width: 100%;
  position: relative;
  > .send-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("/svg/send_message.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 0.4rem;
  }
`;

const CommentInputContainer = styled.textarea`
  width: 100%;
  height: 6rem;
  padding: 2rem;
  padding-right: 3.2rem;
  overflow-y: hidden;
  border-radius: 0.4rem;
  ${({ theme }) => theme.font.inter};
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  border: 0.1rem solid var(--gray-300);
  background: var(--white);
  resize: none;
  outline: none;
`;

interface CommentInputProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  contractId: string;
  refetch: () => void;
}

const CommentTextarea = ({ contractId, refetch }: CommentInputProps) => {
  const [value, setValue] = useState("");
  const mutation = useMutation<
    PostCommentSuccessResponse,
    AxiosError,
    PostCommentForm
  >({
    mutationFn: (data) => postComment(data),
    onSuccess: () => {
      setValue("");
      refetch();
    },
  });

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
      if (e.shiftKey) {
        return;
      } else {
        e.preventDefault();
        mutation.mutate({
          content: value,
          contractId: contractId,
        });
      }
    }
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    mutation.mutate({
      content: value,
      contractId: contractId,
    });
  };

  return (
    <Wrapper onSubmit={onSubmit}>
      <CommentInputContainer
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Add comment on Contract"
      />
      <button type="submit" className="send-icon" />
    </Wrapper>
  );
};

export default CommentTextarea;
