import { SignImageForm } from "../components/interfaces/contract.interface";
import { FileListForm } from "../components/interfaces/file.intrefaces";
import { privateClient } from "./axiosInstance";

export const uploadAdContent = async (data: FormData) => {
  const response = await privateClient.post(
    "/api/v1/upload/advertising-product",
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};

export const uploadAdContentList = async (data: FileListForm) => {
  const response = await privateClient.post(
    "/api/v1/upload/advertising-product",
    data
  );

  return response.data;
};

export const postAdContent = async (data: FileListForm) => {
  const response = await privateClient.post(
    "/api/v1/advertising_product",
    data
  );

  return response.data;
};

export const uploadSignImage = async (data: FormData) => {
  const response = await privateClient.post("/api/v1/upload/sign-image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
};

export const postSignImage = async (data: SignImageForm) => {
  const response = await privateClient.post("/api/v1/contract/sign", data);

  return response.data;
};
