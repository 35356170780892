import React from "react";
import styled from "styled-components";
import ImageContainer from "../common/ImageContainer";
import StyledButton from "../button/StyledButton";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  color: var(--white);
  text-align: center;
  padding-top: 19rem;
  padding-bottom: 19rem;
  .title {
    color: var(--white);
    ${({ theme }) => theme.font.h1};
    ${({ theme }) => theme.font.larken};
  }
  .description {
    margin-top: 2rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    white-space: pre-wrap;
  }
  position: relative;
`;

const ImageListContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin: 2rem auto;
`;

const ScrollDown = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 3.6rem;
  right: 11.2rem;
  ${({ theme }) => theme.font.body3};
  ${({ theme }) => theme.font.regular};
  color: var(--gray-100);
  &::after {
    content: "";
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    background-image: url("/svg/arrow_down_circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 0.4rem;
  }
`;

const data = [
  "/image/main/banner_image_1.png",
  "/image/main/banner_image_2.png",
  "/image/main/banner_image_3.png",
  "/image/main/banner_image_4.png",
  "/image/main/banner_image_5.png",
];

const Banner = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <h1 className="title">All-In-One Celebrity Business Platform</h1>
      <h4 className="description">
        Celevu is an AI-optimized celebrity business platform that streamlines
        the search, recommendation, and contracting process for{"\n"}
        celebrities aligned with campaign goals and brand image.{"\n"}
        It offers contracts for Digital Display Advertising and Social Media
        Seeding, along with offline options like event invitations and{"\n"}
        brand ambassador contracts.
      </h4>
      <ImageListContainer>
        {data.map((image, index) => {
          return (
            <ImageContainer
              borderRadius={0.4}
              width={20}
              height={28}
              key={`banner_image_${index}`}
            >
              <img src={image} alt="banner_image" />
            </ImageContainer>
          );
        })}
      </ImageListContainer>
      <StyledButton
        size="lg"
        color="purple"
        label="Get started"
        mt={10}
        onClick={() => navigate("/login")}
      />
      <ScrollDown>Scroll down</ScrollDown>
    </Container>
  );
};

export default Banner;
