import React, { useState } from "react";
import Section from "../layout/Section";
import ImageView from "./ImageView";
import styled from "styled-components";
import CelebSideInformation from "./CelebSideInformation";
import { CelebData } from "../interfaces/celeb.interfaces";

const Wrapper = styled.div`
  display: flex;
  gap: 4.4rem;
`;

interface CelebInformationSectionProps {
  celebData: CelebData;
}

const CelebInformationSection = ({
  celebData,
}: CelebInformationSectionProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  return (
    <Section mt={4}>
      <Wrapper>
        <ImageView
          celebData={celebData}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
        <CelebSideInformation
          celebData={celebData}
          selectedIndex={selectedIndex}
        />
      </Wrapper>
    </Section>
  );
};

export default CelebInformationSection;
