import React from "react";

interface LogoIconProps {
  width?: number;
  height?: number;
  unit?: string;
}

const LogoIcon = ({
  width = 15.9,
  height = 4.4,
  unit = "rem",
}: LogoIconProps) => {
  return (
    <svg
      width={`${width}${unit}`}
      height={`${height}${unit}`}
      viewBox="0 0 159 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3726_7491)">
        <path
          d="M95.2765 14.4463V14.2705H105.227V14.5039C104.757 15.8437 104.349 18.6929 105.577 21.721L112.366 38.074L118.804 21.721C120.033 18.7536 119.505 15.8437 118.92 14.5039V14.2705H124.715V14.5039C124.188 15.3193 122.782 17.6472 121.731 20.2085L112.659 43.1967H108.562L99.6053 21.8968C98.2578 18.7536 96.6208 15.8437 95.2734 14.4463H95.2765Z"
          fill="#252525"
        />
        <path
          d="M28.9043 28.9563C28.9043 19.8901 34.7878 14.04 42.5521 14.04C50.3165 14.04 55.0263 20.0083 55.0263 28.0227H34.9646C35.1993 34.9852 38.5526 40.0745 44.9665 40.0745C48.2618 40.0745 51.3804 38.8469 54.7916 35.6278L54.9684 35.8036C52.9686 40.3655 48.4966 43.9362 42.671 43.9362C35.3182 43.9362 28.9043 38.3801 28.9043 28.9593V28.9563ZM35.0225 26.501L49.2587 25.622C49.0239 20.0659 46.8474 16.0285 42.4942 16.0285C37.6685 16.0285 35.2572 20.7661 35.0225 26.501Z"
          fill="#252525"
        />
        <path
          d="M66.1431 43.4635H58.141V43.2877C58.4946 41.8843 59.3756 38.55 59.3756 35.1551V9.06613C59.3756 5.67429 58.4915 2.45522 57.9642 1.17002L57.9062 0.994213L59.0829 0.818407L64.9085 0V35.1582C64.9085 38.55 65.7925 41.8843 66.1431 43.2907V43.4665V43.4635Z"
          fill="#252525"
        />
        <path
          d="M69.877 28.9563C69.877 19.8901 75.7604 14.04 83.5248 14.04C91.2891 14.04 95.9959 20.0083 95.9959 28.0227H75.9342C76.1689 34.9852 79.5222 40.0745 85.9361 40.0745C89.2314 40.0745 92.35 38.8469 95.7612 35.6278L95.938 35.8036C93.9382 40.3655 89.4662 43.9362 83.6437 43.9362C76.2908 43.9362 69.877 38.3801 69.877 28.9593V28.9563ZM75.9982 26.501L90.2344 25.622C89.9996 20.0659 87.8231 16.0285 83.4699 16.0285C78.6442 16.0285 76.2329 20.7661 75.9982 26.501Z"
          fill="#252525"
        />
        <path
          d="M158.326 34.7246C157.717 36.7858 156.744 40.12 154.244 40.12C152.537 40.12 152.111 38.6651 152.111 36.1795L152.01 34.2669V14.3887H145.855V14.6251C146.069 15.4314 146.471 16.956 146.758 18.7808C146.95 19.9932 147.09 21.3421 147.09 22.697V35.0398C147.09 35.5066 147.09 36.1522 147.148 36.7948C144.794 38.9591 142.477 40.1867 140.005 40.1867C136.71 40.1867 133.558 38.1407 133.558 33.2848V14.3887H127.461V14.6251C127.638 15.4314 128.028 16.956 128.308 18.7808C128.455 19.7113 128.571 20.7207 128.616 21.7513C128.632 22.0665 128.638 22.3818 128.638 22.697V33.9274C128.638 37.1465 129.589 39.4683 131.07 41.0536C132.921 43.039 135.603 43.8726 138.286 43.8726C139.99 43.8726 141.557 43.5118 143.017 42.7844C143.017 42.7874 143.02 42.7813 143.017 42.7844C144.337 42.1296 144.965 41.1597 146.117 39.8896C146.511 39.4592 146.892 38.9924 147.267 38.4923C147.337 39.0894 147.413 39.7047 147.492 40.2928C147.666 41.6113 148.422 42.8056 149.589 43.4512C149.596 43.4543 149.605 43.4603 149.611 43.4633C150.242 43.8059 150.943 43.9999 151.681 43.9999C156.07 43.9999 158.567 40.3625 158.997 34.8458L158.323 34.7246H158.326Z"
          fill="#252525"
        />
        <path
          d="M17.3273 15.9741C20.6836 15.9741 23.793 18.4444 25.9391 21.2392H26.2195V15.8134C22.4883 14.5585 18.7082 13.8311 15.675 13.8311C6.85897 13.8311 0 19.9024 0 28.8503C0 37.7983 6.9992 43.8727 15.5378 43.8727C19.1319 43.8727 22.909 42.9815 26.2226 41.8903V36.4252H25.9421C23.5613 39.5018 20.7659 41.7296 17.4218 41.7296C12.4742 41.7296 8.10578 37.9801 6.94737 31.9815C6.94737 31.9815 6.94737 31.9876 6.94737 31.9906C6.5023 29.8931 5.82555 29.4354 4.21293 28.8564C5.82555 28.2775 6.46877 27.8137 6.91384 25.7161C6.91384 25.7161 6.91384 25.7222 6.91384 25.7252C7.96555 19.7994 12.0108 15.9801 17.3273 15.9801V15.9741Z"
          fill="#252525"
        />
        <path
          d="M15.9546 26.1734C13.7201 26.7917 13.2202 27.2919 12.6013 29.5289C11.9825 27.2919 11.4825 26.7887 9.24805 26.1734C11.4825 25.555 11.9398 25.1004 12.5556 22.8604C13.1744 25.0973 13.717 25.555 15.9546 26.1734Z"
          fill="#AF6EFD"
        />
      </g>
      <defs>
        <clipPath id="clip0_3726_7491">
          <rect width="159" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoIcon;
