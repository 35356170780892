import { css } from "styled-components";

export const bottomBox = css`
  background-color: var(--white);
  padding: 4rem;
  padding-top: 0.1rem;
  margin-top: -0.1rem;
  border: 0.1rem solid var(--gray-300);
  border-top: none;
`;
