import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import styled from "styled-components";
import { postAdvertiseComment, postComment } from "../../api/contract";
import {
  PostCommentForm,
  PostCommentSuccessResponse,
} from "../interfaces/contract.interface";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";

const Wrapper = styled.form`
  width: 100%;
  position: relative;
  > .send-icon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("/svg/send_message.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 0.4rem;
  }
`;

const CommentInputContainer = styled.input`
  width: 100%;
  height: 6rem;
  padding: 2rem;
  border-radius: 0.4rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  border: 0.1rem solid var(--gray-300);
  background: var(--white);
`;

interface AdvertiseCommentInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  advertisingProductId: string;
  contractId: string;
  refetch: () => void;
}

const AdvertiseCommentInput = ({
  advertisingProductId,
  contractId,
  refetch,
}: AdvertiseCommentInputProps) => {
  const [value, setValue] = useState("");
  const mutation = useMutation<
    PostCommentSuccessResponse,
    AxiosError,
    PostCommentForm
  >({
    mutationFn: (data) => postAdvertiseComment(data),
    onSuccess: () => {
      setValue("");
      refetch();
    },
  });
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    const data = {
      content: value,
      contractId: contractId,
      advertisingProductId: advertisingProductId,
    };
    mutation.mutate(data);
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <CommentInputContainer
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Add comment on Material"
      />
      <button type="submit" className="send-icon" />
    </Wrapper>
  );
};

export default AdvertiseCommentInput;
