import React, { useEffect, useState } from "react";
import { ContentBox } from "./ContentBox";
import ImageScroll from "../common/ImageScroll";
import {
  AdvertisingProductImage,
  ContractData,
} from "../interfaces/contract.interface";
import { useContentList } from "../../hooks/useContract";

interface FinalAdContentProps {
  contractId: string;
}

const FinalAdContent = ({ contractId }: FinalAdContentProps) => {
  const { data, isPending, refetch: refetchList } = useContentList(contractId);
  const [finalImageList, setFinalImageList] = useState<string[]>([]);

  const getFirstChildrenImgUrls = (
    data: AdvertisingProductImage[]
  ): string[] => {
    return data.map((item) => item.children[0].imgUrl);
  };

  useEffect(() => {
    if (data?.data.advertisingImgs.length && !finalImageList.length) {
      setFinalImageList(getFirstChildrenImgUrls(data.data.advertisingImgs));
    }
  }, [data, finalImageList]);

  return (
    <ContentBox title="Final ad contents">
      <ImageScroll
        imgList={finalImageList}
        maxWidth={104.4}
        width={16}
        height={16}
        gap={1.6}
        unit="rem"
      />
    </ContentBox>
  );
};

export default FinalAdContent;
