import React, { useState } from "react";

const defaultColor = {
  true: "var(--purple-300)",
  false: "var(--gray-900)",
};

interface FavoriteColorProps {
  true: string;
  false: string;
}

interface CheckIconProps {
  size?: number;
  color?: FavoriteColorProps;
  status?: boolean;
}

export const CheckIcon = ({
  size = 48,
  color = defaultColor,
  status = false,
}: CheckIconProps) => {
  const path = status
    ? "M17.5621 26.4788L28.2288 15.8121L27.1775 14.7671L17.5621 24.4038L12.7308 19.5725L11.7071 20.6175L17.5621 26.4788ZM20.0054 35C17.9357 35 15.9913 34.6064 14.1721 33.8192C12.3532 33.0319 10.7638 31.959 9.40375 30.6004C8.04375 29.2415 6.96986 27.6535 6.18208 25.8363C5.39403 24.019 5 22.0754 5 20.0054C5 17.9312 5.39361 15.9811 6.18083 14.155C6.96806 12.3292 8.04097 10.7408 9.39958 9.39C10.7585 8.03917 12.3465 6.96986 14.1637 6.18208C15.981 5.39403 17.9246 5 19.9946 5C22.0688 5 24.0189 5.39361 25.845 6.18083C27.6708 6.96806 29.2592 8.03639 30.61 9.38583C31.9608 10.7353 33.0301 12.3222 33.8179 14.1467C34.606 15.9708 35 17.9201 35 19.9946C35 22.0643 34.6064 24.0088 33.8192 25.8279C33.0319 27.6468 31.9636 29.2362 30.6142 30.5963C29.2647 31.9563 27.6778 33.0301 25.8533 33.8179C24.0292 34.606 22.0799 35 20.0054 35Z"
    : "M17.5621 26.4788L28.2288 15.8121L27.1775 14.7671L17.5621 24.4038L12.7308 19.5725L11.7071 20.6175L17.5621 26.4788ZM20.0054 35C17.9357 35 15.9913 34.6064 14.1721 33.8192C12.3532 33.0319 10.7638 31.959 9.40375 30.6004C8.04375 29.2415 6.96986 27.6535 6.18208 25.8363C5.39403 24.019 5 22.0754 5 20.0054C5 17.9312 5.39361 15.9811 6.18083 14.155C6.96806 12.3292 8.04097 10.7408 9.39958 9.39C10.7585 8.03917 12.3465 6.96986 14.1637 6.18208C15.981 5.39403 17.9246 5 19.9946 5C22.0688 5 24.0189 5.39361 25.845 6.18083C27.6708 6.96806 29.2592 8.03639 30.61 9.38583C31.9608 10.7353 33.0301 12.3222 33.8179 14.1467C34.606 15.9708 35 17.9201 35 19.9946C35 22.0643 34.6064 24.0088 33.8192 25.8279C33.0319 27.6468 31.9636 29.2362 30.6142 30.5963C29.2647 31.9563 27.6778 33.0301 25.8533 33.8179C24.0292 34.606 22.0799 35 20.0054 35ZM20 33.5896C23.785 33.5896 26.9963 32.2699 29.6338 29.6304C32.271 26.9907 33.5896 23.7806 33.5896 20C33.5896 16.215 32.271 13.0037 29.6338 10.3662C26.9963 7.72903 23.785 6.41042 20 6.41042C16.2194 6.41042 13.0093 7.72903 10.3696 10.3662C7.73014 13.0037 6.41042 16.215 6.41042 20C6.41042 23.7806 7.73014 26.9907 10.3696 29.6304C13.0093 32.2699 16.2194 33.5896 20 33.5896Z";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill={status ? color.true : color.false}
    >
      <path d={path} />
    </svg>
  );
};

interface CheckButtonProps {
  size?: number;
  color?: FavoriteColorProps;
  status: boolean;
  setStatus: (checked: boolean) => void;
}

const CheckButton = ({
  size = 40,
  color = defaultColor,
  status = false,
  setStatus,
}: CheckButtonProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setStatus(!status);
  };

  return (
    <div
      onClick={handleClick}
      style={{
        cursor: "pointer",
        width: size,
        height: size,
      }}
    >
      <CheckIcon size={size} color={color} status={status} />
    </div>
  );
};

export default CheckButton;
