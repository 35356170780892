import React from "react";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import StyledButton from "../button/StyledButton";
import useStockCartList from "../../hooks/useStockCartList";
import { formatNumberWithCommas } from "../../utils/utils";

const Wrapper = styled.div`
  width: 32rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Subtitle = styled.span`
  display: inline-block;
  ${({ theme }) => theme.font.title3};
  ${({ theme }) => theme.font.bold};
  margin-top: 2.4rem;
`;

const DetailText = styled.span`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  line-height: 130%;
`;

const ButtonList = styled.div`
  display: flex;
  margin-top: 4rem;
`;

const Description = styled.div`
  ${({ theme }) => theme.font.title3};
  ${({ theme }) => theme.font.regular};
  margin-top: 4rem;
  color: var(--gray-600);
`;

interface CelebSideInformationProps {
  celebData: CelebData;
  selectedIndex: number;
}

const CelebSideInformation = ({
  celebData,
  selectedIndex,
}: CelebSideInformationProps) => {
  const celebPhotoList = celebData.celebStockData;
  const celebId = celebData.celebInfo.id;
  const { addStock } = useStockCartList(celebId);

  return (
    <Wrapper>
      <Subtitle>
        Photo ID :{" "}
        <DetailText>{celebPhotoList[selectedIndex].photoId}</DetailText>
      </Subtitle>
      <Subtitle>Photo Details : </Subtitle>
      <DetailText>
        {celebPhotoList[selectedIndex].extension
          ? celebPhotoList[selectedIndex].extension?.slice(1).toUpperCase()
          : ""}
      </DetailText>
      <DetailText>
        {celebPhotoList[selectedIndex].width &&
        celebPhotoList[selectedIndex].height
          ? "Max size : " +
            formatNumberWithCommas(celebPhotoList[selectedIndex].width) +
            " x " +
            formatNumberWithCommas(celebPhotoList[selectedIndex].height) +
            " px"
          : ""}
      </DetailText>
      <Subtitle>Photo Contributor : </Subtitle>
      <DetailText>{celebPhotoList[selectedIndex].photoContributor}</DetailText>
      <Description>{celebPhotoList[selectedIndex].description}</Description>
      <ButtonList>
        <StyledButton
          label="TRY"
          color="white"
          size="md"
          onClick={() =>
            window.open(celebPhotoList[selectedIndex].imgUrl, "_blank")
          }
        />
        <StyledButton
          label="ADD"
          color="black"
          size="md"
          onClick={() => addStock(celebPhotoList[selectedIndex])}
        />
      </ButtonList>
    </Wrapper>
  );
};

export default CelebSideInformation;
