import React from "react";
import styled from "styled-components";
import { ErrorMessage, Label, LabelGroup } from "../input/ContractInput";
import { UseFormSetValue } from "react-hook-form";
import { ContractFormData } from "../interfaces/contract.interface";
import { mediaList } from "../../utils/utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const MediaButtonList = styled.div`
  display: flex;
  width: 100%;
  gap: 1.6rem;
  padding: 0 0.4rem;
`;

const MediaButton = styled.button<{
  $url: string;
  $urlDisabled: string;
  $status: boolean;
}>`
  width: 4rem;
  height: 4rem;
  ${(props) =>
    props.$status
      ? `background-image: url(${props.$url})`
      : `background-image: url(${props.$urlDisabled})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

interface AdvertisingMediaProps {
  setValue: UseFormSetValue<ContractFormData>;
  errorMessage?: string;
  value?: string[];
}

const AdvertisingMedia = ({
  value = [],
  setValue,
  errorMessage,
}: AdvertisingMediaProps) => {
  return (
    <Container>
      <LabelGroup>
        <Label $required={true}>Advertising media</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <MediaButtonList>
        {mediaList.map((data) => {
          const isSelected = value.includes(data.value);
          return (
            <MediaButton
              key={`media button ${data.id}`}
              type="button"
              $url={data.img}
              $urlDisabled={data.imgDisabled}
              $status={isSelected}
              onClick={(e) => {
                e.preventDefault();
                setValue(
                  "advertisingMedia",
                  isSelected
                    ? value.filter((value) => value !== data.value)
                    : [...value, data.value]
                );
              }}
            />
          );
        })}
      </MediaButtonList>
    </Container>
  );
};
export default AdvertisingMedia;
