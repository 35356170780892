import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { putLike } from "../../api/like";

const defaultColor = {
  true: "var(--crimson-600)",
  false: "var(--gray-600)",
};

interface FavoriteColorProps {
  true: string;
  false: string;
}

interface FavoriteIconProps {
  size?: number;
  color?: FavoriteColorProps;
  status?: boolean;
}

export const FavoriteIcon = ({
  size = 48,
  color = defaultColor,
  status = false,
}: FavoriteIconProps) => {
  const path = status
    ? "m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Z"
    : "m480-173.85-30.31-27.38q-97.92-89.46-162-153.15-64.07-63.7-101.15-112.35-37.08-48.65-51.81-88.04Q120-594.15 120-634q0-76.31 51.85-128.15Q223.69-814 300-814q52.77 0 99 27t81 78.54Q514.77-760 561-787q46.23-27 99-27 76.31 0 128.15 51.85Q840-710.31 840-634q0 39.85-14.73 79.23-14.73 39.39-51.81 88.04-37.08 48.65-100.77 112.35Q609-290.69 510.31-201.23L480-173.85Zm0-54.15q96-86.77 158-148.65 62-61.89 98-107.39t50-80.61q14-35.12 14-69.35 0-60-40-100t-100-40q-47.77 0-88.15 27.27-40.39 27.27-72.31 82.11h-39.08q-32.69-55.61-72.69-82.5Q347.77-774 300-774q-59.23 0-99.62 40Q160-694 160-634q0 34.23 14 69.35 14 35.11 50 80.61t98 107q62 61.5 158 149.04Zm0-273Z";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      viewBox="0 -960 960 960"
      fill={status ? color.true : color.false}
    >
      <path d={path} />
    </svg>
  );
};

interface FavoriteButtonProps {
  id: string;
  size?: number;
  color?: FavoriteColorProps;
  status?: boolean;
}

interface LikeResponse {
  ok: boolean;
  statusCode: number;
  message: string;
  data: boolean;
}

interface LikeErrorResponse {
  ok: boolean;
  statusCode: number;
  data: string;
}

const FavoriteButton = ({
  id,
  size = 48,
  color = defaultColor,
  status,
}: FavoriteButtonProps) => {
  const [isLike, setIsLike] = useState<boolean | undefined>(status);

  const mutation = useMutation<
    LikeResponse,
    AxiosError<LikeErrorResponse>,
    string
  >({
    mutationFn: (id: string) => putLike(id),
    onSuccess: () => {
      setIsLike((prev) => !prev);
    },
    onError: (error) => {
      console.error("Failed to update like status:", error);
    },
  });

  useEffect(() => {
    setIsLike(status);
  }, [status]);

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    mutation.mutate(id);
  };

  return (
    <div
      onClick={(e) => handleClick(e)}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FavoriteIcon size={size} color={color} status={isLike} />
    </div>
  );
};

export default FavoriteButton;
