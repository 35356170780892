import React from "react";
import styled from "styled-components";
import CheckIcon from "../../styles/CheckIcon";

const statusList = [
  {
    order: 1,
    value: "REQUEST",
    label: "Contract request",
  },
  {
    order: 2,
    value: "NEGOTIATION",
    label: "In negotiation",
  },
  {
    order: 3,
    value: "STAKEHOLDER",
    label: "Stakeholder signatures",
  },
  {
    order: 4,
    value: "CONTENT_REVIEW",
    label: "Content review",
  },
  {
    order: 5,
    value: "READY_FOR",
    label: "Ready for advertisement",
  },
];

const StatusBarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  padding: 1.2rem 3rem;
  margin-top: 1.6rem;
  gap: 2rem;
  :last-child {
    &::after {
      display: none;
    }
  }
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  &::after {
    content: "";
    width: 2rem;
    height: 2rem;
    display: inline-block;
    background-image: url("/svg/arrow_right.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`;

const Status = styled.div<{ $current: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.$current ? "var(--gray-900)" : "var(--gray-500)")};
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

interface StatusBarProps {
  currentStatus: string;
}

const StatusBar = ({ currentStatus }: StatusBarProps) => {
  const getCurrentOrder = (statusValue: string) => {
    return (
      statusList.find((status) => status.value === statusValue)?.order || 0
    );
  };

  const currentOrder = getCurrentOrder(currentStatus);

  return (
    <StatusBarContainer>
      {statusList.map((status) => {
        const isCompleted = status.order < currentOrder;
        const isCurrent = status.order === currentOrder;

        let iconStatus: "checked" | "current" | "notChecked";
        if (isCompleted) {
          iconStatus = "checked";
        } else if (isCurrent) {
          iconStatus = "current";
        } else {
          iconStatus = "notChecked";
        }

        return (
          <StatusContainer key={status.value}>
            <CheckIcon status={iconStatus} />
            <Status $current={isCurrent}>{status.label}</Status>
          </StatusContainer>
        );
      })}
    </StatusBarContainer>
  );
};

export default StatusBar;
