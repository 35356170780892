import React from "react";
import styled from "styled-components";
import StyledButton from "../button/StyledButton";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  text-align: center;
  /*padding-top: 19rem;*/
  /*padding-bottom: 19rem;*/
  .title {
    color: var(--black);
    ${({ theme }) => theme.font.h1};
    ${({ theme }) => theme.font.larken};
  }
  .description {
    color: var(--gray-600);
    margin-top: 2rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    white-space: pre-wrap;
  }
  position: relative;
`;

const Discover = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="title">Discover More & Get in Touch!</div>
      <div className="description">
        Sign up to experience more of our services, and feel free to email us if
        you have any questions!
      </div>
      <StyledButton
        color="purple"
        label="Sign up"
        style={{ width: "70rem" }}
        mt={4}
        onClick={() => navigate("/signup")}
      />
    </Container>
  );
};

export default Discover;
