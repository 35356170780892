import React from "react";
import { useCelebOnCelevu } from "../../../hooks/useCeleb";
import styled, { css } from "styled-components";
import ImageContainer from "../../common/ImageContainer";
import { formatNumber } from "../../../utils/utils";

const Container = styled.div`
  background-color: var(--gray-050);
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Title = styled.div`
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.regular};
  color: var(--gray-800);
`;

const TotalDataList = styled.div`
  display: flex;
  gap: 2rem;
`;

const TotalData = styled.div`
  border-radius: 0.8rem;
  width: 32rem;
  height: 12rem;
  border: 0.1rem solid var(--gray-300);
  background: var(--white);
  align-content: center;
  text-align: center;
  > .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    color: var(--gray-900);
  }
  > .score {
    margin-top: 0.8rem;
    ${({ theme }) => theme.font.h2};
    ${({ theme }) => theme.font.bold};
    color: var(--gray-900);
  }
`;

const CelebGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 32rem);
  gap: 2rem;
`;

const Celeb = styled.div`
  width: 32rem;
  height: 36rem;
  background-color: var(--white);
  border-radius: 0.8rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .celeb-name {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 12rem;
    padding: 2.2rem 2rem;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 0.8rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
    > .name {
      color: var(--white);
      ${({ theme }) => theme.font.title1};
      ${({ theme }) => theme.font.bold};
    }
    > .score-list {
      display: flex;
      align-items: center;
      width: 100%;
      color: var(--white);
      gap: 1.2rem;
      > .score {
        display: flex;
        align-items: center;
        gap: 0.8rem;
      }
      > .link {
        cursor: pointer;
        margin-left: auto;
        display: flex;
        align-items: center;
        color: var(--gray-300);
        ${({ theme }) => theme.font.body3};
        ${({ theme }) => theme.font.underline};
        ${({ theme }) => theme.font.regular};
        &::after {
          content: "";
          width: 2rem;
          height: 2rem;
          display: inline-block;
          background-image: url("/svg/dashboard/arrow_right.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }
    }
  }
  > .celeb-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.8rem;
    width: 100%;
    > .score {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      ${({ theme }) => theme.font.body2};
      ${({ theme }) => theme.font.regular};
      color: var(--gray-900);
    }
  }
`;

const iconList = {
  favorite: css`
    background-image: url("/svg/dashboard/favorite.svg");
    width: 2rem;
    height: 2rem;
  `,
  assignment: css`
    background-image: url("/svg/dashboard/assignment.svg");
    width: 2rem;
    height: 2rem;
  `,
  article: css`
    background-image: url("/svg/dashboard/article.svg");
    width: 2rem;
    height: 2rem;
  `,
  trendScore: css`
    background-image: url("/svg/dashboard/trend_score.svg");
    width: 2rem;
    height: 2rem;
  `,
  instagramScore: css`
    background-image: url("/svg/dashboard/instagram_score.svg");
    width: 2rem;
    height: 2rem;
  `,
};

const Icon = styled.span<{
  $type:
    | "favorite"
    | "assignment"
    | "article"
    | "trendScore"
    | "instagramScore";
}>`
  ${({ $type }) => iconList[$type]}
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const MyCelebList = () => {
  const { data, isPending } = useCelebOnCelevu();
  if (isPending) return <>Loading</>;
  if (!data || !data.data.celebList.length) {
    return (
      <Container>
        <Title>Celebrities on celevu</Title>
        <div>no celeb</div>
      </Container>
    );
  }
  return (
    <Container>
      <Title>Celebrities on celevu</Title>
      <TotalDataList>
        <TotalData>
          <div className="title">
            <Icon $type="favorite" />
            Favorite
          </div>
          <div className="score">{data?.data.totalLikes}</div>
        </TotalData>
        <TotalData>
          <div className="title">
            <Icon $type="article" />
            Contract Drafting
          </div>
          <div className="score">{data?.data.contractDraftingCount}</div>
        </TotalData>
        <TotalData>
          <div className="title">
            <Icon $type="assignment" />
            Contract Completion
          </div>
          <div className="score">{data?.data.contractCompletionCount}</div>
        </TotalData>
      </TotalDataList>
      <CelebGrid>
        {data?.data.celebList.map((celeb) => {
          return (
            <Celeb key={celeb.id}>
              <ImageContainer width={32} height={32}>
                <img src={celeb.image} alt={celeb.name + "image"} />
                <div className="celeb-name">
                  <div className="name">{celeb.name}</div>
                  <div className="score-list">
                    <div className="score">
                      <Icon $type="trendScore" />
                      {formatNumber(celeb.searchVolume)}
                    </div>
                    <div className="score">
                      <Icon $type="instagramScore" />
                      {formatNumber(celeb.instagramFollowersCnt)}
                    </div>
                    {/*<div className="link">Detailed page</div>*/}
                  </div>
                </div>
              </ImageContainer>
              <div className="celeb-info">
                <div className="score">
                  <Icon $type="favorite" />
                  {formatNumber(celeb.avgLikes)}
                </div>
                <div className="score">
                  <Icon $type="article" />
                  {formatNumber(celeb.contracting)}
                </div>
                <div className="score">
                  <Icon $type="assignment" />
                  {formatNumber(celeb.completedContract)}
                </div>
              </div>
            </Celeb>
          );
        })}
      </CelebGrid>
    </Container>
  );
};

export default MyCelebList;
