import axios from "axios";
import { requestRefresh } from "./auth";
import { getTokens, updateTokens } from "../atoms/auth";

export const getEnv = () => {
  return process.env.REACT_APP_API_URL;
}

export const publicClient = axios.create({
  baseURL: getEnv(),
  headers: {
    "Content-Type": "application/json",
  },
});

export const privateClient = axios.create({
  baseURL: getEnv(),
  headers: {
    "Content-Type": "application/json",
  },
});

privateClient.interceptors.request.use(
  async (config) => {
    const { accessToken, refreshToken } = getTokens();

    if (!accessToken && refreshToken) {
      const newTokens = await requestRefresh(refreshToken);
      updateTokens(newTokens.accessToken, newTokens.refreshToken);
      config.headers.Authorization = `Bearer ${newTokens.accessToken}`;
      return config;
    }

    if (accessToken && config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
