import React from "react";
import { LayoutWithNavBack } from "../components/layout/Layout";
import ContractHistory from "../components/dashboard/ContractHistory";

const ContractHistoryPage = () => {
  return (
    <LayoutWithNavBack>
      <ContractHistory />
    </LayoutWithNavBack>
  );
};

export default ContractHistoryPage;
