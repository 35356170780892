import React from "react";
import styled from "styled-components";
import { useAtom } from "jotai";
import { contractFormAtom } from "../../../atoms/contract";
import ImageScroll from "../../common/ImageScroll";
import StyledButton from "../../button/StyledButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useCreateContract } from "../../../hooks/useContract";
import { FormContainer, StepDescription } from "../../common/FormContainer";

const Title = styled.div`
  width: 50%;
  margin-right: auto;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid var(--gray-900);
`;

const ContractSection = styled.div`
  width: 100%;
  b {
    ${({ theme }) => theme.font.bold};
  }
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const Flex = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Agreement = styled.div`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  color: var(--gray-900);
  white-space: pre-wrap;
  text-align: left;
  margin-right: auto;
`;

const ContractReview = () => {
  const [contractForm] = useAtom(contractFormAtom);
  const navigate = useNavigate();
  const { confirmContract } = useCreateContract({
    onSuccess: (response) => {
      navigate(
        `../complete?projectId=${response.data.projectId}&contractId=${response.data.id}`
      );
    },
  });

  if (!contractForm.projectData || !contractForm.celebInfo)
    return <Navigate to="../" />;

  const onClickSubmit = () => {
    if (!contractForm.projectData || !contractForm.celebInfo) {
      return null;
    }
    const data = {
      projectId: contractForm.projectData.id,
      celebAgencyId: contractForm.celebInfo.celebAgencyId,
      celebId: contractForm.celebInfo.id,
      currencyType: contractForm.currencyType,
      contractType: contractForm.contractType,
      amount: contractForm.amount,
      startDate: contractForm.startDate,
      endDate: contractForm.endDate,
      advertisingMedia: contractForm.advertisingMedia,
      country: contractForm.country,
      stockImage: contractForm.stockCart.map((data) => data.id),
      additionalCondition: contractForm.additionalCondition || "",
      comment: contractForm.comment || "",
    };
    confirmContract(data);
  };

  return (
    <FormContainer>
      <StepDescription step={4} length={5}>
        Please review the contract details.{"\n"}
        Once you click <b>Confirm button</b>, the contract will be{"\n"}
        generated and sent to <b>Party B.</b>
      </StepDescription>
      <ContractSection>
        <Title>Contractors</Title>
        <FlexColumn>
          <Flex>
            <b>Party A</b>
            <span>{contractForm.projectData?.companyName}</span>
          </Flex>
          <Flex>
            <b>Party B</b>
            <span>{contractForm.celebInfo?.name}</span>
          </Flex>
        </FlexColumn>
      </ContractSection>
      <div
        style={{ width: "100%", borderTop: "0.4rem solid var(--gray-900)" }}
      />
      <Agreement>
        This contract defines the terms and conditions{"\n"}
        between the parties, detailing roles, responsibilities,{"\n"}
        payment terms, and duration. It also covers{"\n"}
        confidentiality, dispute resolution, and termination{"\n"}
        clauses. By signing, both parties agree to the stated{"\n"}
        terms and their obligations.
      </Agreement>
      <ContractSection>
        <Title>Contract Details</Title>
        <FlexColumn>
          <b>Project title</b>
          <span>{contractForm.projectData?.title}</span>
        </FlexColumn>
        <FlexColumn>
          <b>Project description</b>
          <span>{contractForm.projectData?.description}</span>
        </FlexColumn>
        <Flex>
          <b>Amount</b>
          <span>{contractForm.amount}</span>
        </Flex>
        <Flex>
          <b>Ad posting date</b>
          <span>
            {contractForm.startDate}-{contractForm.endDate}
          </span>
        </Flex>
        <Flex>
          <b>Target countries</b>
          <span>
            {contractForm.country.length
              ? contractForm.country.join(", ")
              : "N/A"}
          </span>
        </Flex>
        <Flex>
          <b>Advertising media</b>
          <span>
            {contractForm.advertisingMedia.length
              ? contractForm.advertisingMedia.join(", ")
              : "N/A"}
          </span>
        </Flex>
        {contractForm.additionalCondition && (
          <FlexColumn>
            <b>Additional condition</b>
            <span>{contractForm.additionalCondition}</span>
          </FlexColumn>
        )}
        <FlexColumn>
          <b>Photos to be used</b>
          <ImageScroll
            imgList={contractForm.stockCart.map((data) => data.imgUrl)}
            width={8}
            height={8}
            maxWidth={42}
            gap={0.8}
          ></ImageScroll>
        </FlexColumn>
        {/* {contractForm.comment && (
          <FlexColumn>
            <b>Comments</b>
            <span>{contractForm.comment}</span>
          </FlexColumn>
        )} */}
      </ContractSection>
      <StyledButton
        type="button"
        label="Confirm"
        color="black"
        size="lg"
        onClick={() => onClickSubmit()}
      />
    </FormContainer>
  );
};

export default ContractReview;
