import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import { NavLink } from "react-router-dom";

const FooterContainer = styled.div`
  height: 42.5rem;
  border-top: 0.1rem solid var(--gray-200);
  margin-top: 10rem;
`;

const FooterContent = styled.div`
  width: 160rem;
  margin: 0 auto;
  padding: 10.3rem 9.3rem;
  display: flex;
  color: var(--gray-600);
  line-height: 150%;
  ${({ theme }) => theme.font.body1}
  ${({ theme }) => theme.font.regular}
`;

const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.8rem;
  white-space: pre-wrap;
  > .logo-container {
    display: flex;
    flex-direction: column;
    gap: 1.8rem;
    > .copyright {
      color: var(--gray-700);
      ${({ theme }) => theme.font.body3};
      ${({ theme }) => theme.font.regular};
    }
  }
`;

const LinkContainer = styled.div`
  margin-left: auto;
  margin-top: 2rem;
  margin-right: 16rem;
  display: flex;
  gap: 7.2rem;
`;

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Link = styled(NavLink)`
  color: var(--gray-600);
`;

const IconContainer = styled.div`
  display: flex;
  gap: 1rem;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background-color: var(--gray-800);
    border-radius: 50%;
    cursor: pointer;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <CompanyInfo>
          <div className="logo-container">
            <Logo width={15.9} height={4.4} url="/" />
            <p className="copyright">@2024 CeleVu All rights reserved</p>
          </div>
          <p>
            Room 212, 84, Madeul-ro 13-gil,{"\n"}
            Dobong-gu, Seoul, Republic of Korea, 01411
          </p>
          <p>Tel: +82-2967-0701 | E-mail: support@aligo.ai</p>
        </CompanyInfo>
        <LinkContainer>
          <LinkList>
            <Link to="/Dashboard">Dashboard</Link>
            <Link to="/celebs">Celebs</Link>
          </LinkList>
          <LinkList>
            {/* <Link to="/terms-of-service">Terms of service</Link> */}
            <Link to="">Terms of service</Link>
            {/* <Link to="/contact-us">Contact us</Link> */}
            <Link to="">Contact us</Link>
          </LinkList>
          <IconContainer>
            <div
              className="icon"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/aligo-ai/",
                  "_blank"
                )
              }
            >
              <img src="/svg/footer/linkedin.svg" alt="linked in icon" />
            </div>
            {/* <div className="icon">
              <img src="/svg/footer/youtube.svg" alt="youtube icon" />
            </div> */}
          </IconContainer>
        </LinkContainer>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
