import React from "react";
import styled from "styled-components";
import { bottomBox } from "./commonStyle";
import CommentList from "./CommentList";
import { ContractData } from "../interfaces/contract.interface";
import { userType } from "../interfaces/auth.interfaces";
import { useContractStatus } from "../../hooks/useContractStatus";
import { CONTRACT_STATUS_PRESETS } from "../../configs/contractStatus";

const StepContainer = styled.div`
  ${bottomBox}
  > input {
    width: 100%;
    height: 6rem;
  }
  > .modify {
    margin-top: 6rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    &::before {
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/contract_edit.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 0.4rem;
    }
  }
  > .accept {
    margin-top: 6rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    &::before {
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/contract.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 0.4rem;
    }
  }
`;

interface StepNegotiationProps {
  contractData: ContractData;
  userType: userType | undefined;
  refetch: () => void;
}

export const StepNegotiation = ({
  contractData,
  userType,
  refetch,
}: StepNegotiationProps) => {
  const { updateStatus } = useContractStatus(contractData.contractId, {
    onSuccess: () => {
      refetch();
    },
  });
  if (!userType) return <></>;
  if (userType === "advertiser") {
    return (
      <StepContainer>
        <CommentList
          commentList={contractData.comments}
          contractId={contractData.contractId}
          refetch={refetch}
        />
        <div className="modify">Modify</div>
      </StepContainer>
    );
  } else {
    return (
      <StepContainer>
        <CommentList
          commentList={contractData.comments}
          contractId={contractData.contractId}
          refetch={refetch}
        />
        <div
          className="accept"
          onClick={() =>
            updateStatus(CONTRACT_STATUS_PRESETS.AD_WAITING_CELEB_NO_REQUEST)
          }
        >
          Accept
        </div>
      </StepContainer>
    );
  }
};
