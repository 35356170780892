import React from "react";
import { UseFormSetValue } from "react-hook-form";
import { ContractFormData } from "../interfaces/contract.interface";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DateInput = styled.input`
  font-family: "inter";
  border: 0.1rem solid var(--gray-300);
  border-radius: 0.4rem;
  background-color: var(--white);
  color: var(--gray-900);
  width: 21rem;
  height: 6rem;
  padding: 2rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
`;

const RangeText = styled.div`
  color: var(--gray-900);
  text-align: center;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  .crimson {
    color: var(--crimson-600);
  }
`;

const LabelGroup = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
`;

const Label = styled.label<{ $required?: boolean }>`
  color: var(--gray-600);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};

  &::after {
    content: ${({ $required }) => ($required ? "'*'" : "''")};
    color: var(--crimson-600);
    margin-left: 0.4rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const ErrorMessage = styled.p`
  color: var(--crimson-600);
  text-align: right;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  text-overflow: ellipsis;
`;

interface PeriodProps {
  setValue: UseFormSetValue<any>;
  value: {
    startDate?: string;
    endDate?: string;
  };
  label: string;
  errorMessage?: {
    startDate?: string;
    endDate?: string;
  };
}

const Period = ({ setValue, value, label, errorMessage }: PeriodProps) => {
  const calculateDuration = () => {
    if (!value.startDate || !value.endDate) return null;

    const start = new Date(value.startDate);
    const end = new Date(value.endDate);
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  const today = new Date().toISOString().split("T")[0];
  const duration = calculateDuration();

  return (
    <Container>
      <LabelGroup>
        <Label $required={true}>{label}</Label>
        {errorMessage?.startDate && (
          <ErrorMessage>{errorMessage.startDate}</ErrorMessage>
        )}
        {errorMessage?.endDate && (
          <ErrorMessage>{errorMessage.endDate}</ErrorMessage>
        )}
      </LabelGroup>
      <InputContainer>
        <DateInput
          type="date"
          //min={today}
          value={value.startDate}
          onChange={(e) => {
            setValue("startDate", e.target.value);
            if (value.endDate && e.target.value > value.endDate) {
              setValue("endDate", e.target.value);
            }
          }}
        />
        <DateInput
          type="date"
          min={value.startDate || today}
          value={value.endDate}
          onChange={(e) => setValue("endDate", e.target.value)}
        />
      </InputContainer>
      {duration !== null && (
        <RangeText>
          Ad duration :
          <span className="crimson">
            {" "}
            {duration} day{duration !== 1 ? "s" : ""}
          </span>
        </RangeText>
      )}
    </Container>
  );
};
export default Period;
