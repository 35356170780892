import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Logo from "./Logo";
import { useAtom } from "jotai";
import { clearSession, userDataAtom } from "../../atoms/auth";
import { UserInformation } from "../interfaces/auth.interfaces";

const NavBarContainer = styled.nav`
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-bottom: 0.1rem solid var(--gray-300);
  padding: 1.2rem 8rem;
  color: var(--gray-900);
  max-width: 160rem;
  margin: 0 auto;
`;

const NavLinkList = styled.div`
  display: flex;
  gap: 2.4rem;
`;

const NavLink = styled(Link)<{ $currentPage: boolean }>`
  color: var(--gray-900);
  text-align: center;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};

  ${({ $currentPage, theme }) => $currentPage && theme.font.bold};
`;

const UserStatusContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2rem;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const SignIn = styled.button`
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  background-color: var(--purple-300);
  color: var(--white);
`;

const IconWrapper = styled.div<{ $alert: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  cursor: pointer;
  > .text {
    color: var(--gray-900);
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
  }
  > .logout {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("/svg/logout.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  > .user-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("/svg/user.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &::after {
      display: ${({ $alert }) => ($alert ? "block" : "none")};
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      width: 0.8rem;
      height: 0.8rem;
      background-color: var(--crimson-600);
      border-radius: 50%;
    }
  }
`;

const UserStatus = ({ userData }: { userData: UserInformation | null }) => {
  const [, setUserData] = useAtom(userDataAtom);
  const navigate = useNavigate();
  const logout = () => {
    setUserData(null);
    clearSession();
    navigate("/", { replace: true });
  };
  if (userData && userData.id)
    return (
      <UserStatusContainer>
        <IconWrapper $alert={false}>
          <div className="user-icon" />
          <span className="text">{userData.companyName}</span>
        </IconWrapper>
        <IconWrapper $alert={false} onClick={logout}>
          <div className="logout" />
          <span className="text">Log out</span>
        </IconWrapper>
      </UserStatusContainer>
    );
  else {
    return (
      <UserStatusContainer>
        <Link to="/contact-us">Contact us</Link>
        <Link to="/login">
          <SignIn>Sign in</SignIn>
        </Link>
      </UserStatusContainer>
    );
  }
};

const Navbar = () => {
  const [userData, setUserData] = useAtom(userDataAtom);
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <NavBarContainer>
      <Logo url="/" />
      <NavLinkList>
        {userData?.id && (
          <NavLink
            to="/dashboard"
            $currentPage={currentPath.startsWith("/dashboard")}
          >
            Dashboard
          </NavLink>
        )}
        {userData?.companyType === "advertiser" && (
          <NavLink
            to="/celebs"
            $currentPage={currentPath.startsWith("/celebs")}
          >
            Celebs
          </NavLink>
        )}
      </NavLinkList>
      <UserStatus userData={userData} />
    </NavBarContainer>
  );
};

export default Navbar;
