import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import LogoIcon from "../icon/Logo";

interface LogoProps {
  margin?: string;
  width?: number;
  height?: number;
  unit?: string;
  url?: string;
}

interface LogoWrapperProps {
  $margin?: string;
}

const LogoWrapper = styled.div<LogoWrapperProps>`
  ${({ $margin }) => ($margin ? `margin: ${$margin}` : "")};
  /* text-align: center; */
  cursor: pointer;
`;

const Logo = ({
  margin,
  width = 22,
  height = 4,
  unit = "rem",
  url,
}: LogoProps) => {
  return (
    <Link to={url ? url : ""}>
      <LogoWrapper $margin={margin}>
        <LogoIcon width={width} height={height} unit={unit} />
      </LogoWrapper>
    </Link>
  );
};

export default Logo;
