import React from "react";
import styled from "styled-components";
import { ContractComment } from "../interfaces/contract.interface";
import CommentTextarea from "../input/CommentInput";

const CommentListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

const CommentContainer = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  > .create-at {
    color: var(--gray-600);
    ${({ theme }) => theme.font.body3};
    ${({ theme }) => theme.font.bold};
  }
`;

const UserName = styled.div<{ $type: string }>`
  display: flex;
  color: var(--gray-600);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.bold};
  &::before {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;
    display: block;
    border-radius: 50%;
    ${({ $type }) =>
      $type === "advertiser"
        ? "background-color: var(--crimson-100);"
        : "background-color: var(--purple-100);"}
    content: "";
  }
`;

const Message = styled.div`
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  word-break: break-all;
  width: 100%;
  white-space: pre-wrap;
`;

interface CommentListProps {
  commentList: ContractComment[];
  contractId: string;
  refetch: () => void;
}

const CommentList = ({
  commentList,
  contractId,
  refetch,
}: CommentListProps) => {
  return (
    <CommentListContainer>
      {commentList.map((_, index) => {
        const comment = commentList[commentList.length - 1 - index];
        return (
          <CommentContainer key={comment.id}>
            <UserInfo>
              <UserName $type={comment.companyType}>
                {comment.nickname}
              </UserName>
              <div className="create-at">{comment.createdAt}</div>
            </UserInfo>
            <Message>{comment.content}</Message>
          </CommentContainer>
        );
      })}
      <CommentContainer>
        <CommentTextarea refetch={refetch} contractId={contractId} />
      </CommentContainer>
    </CommentListContainer>
  );
};

export default CommentList;
