import React from "react";
import styled, { css } from "styled-components";
import { UseFormSetValue } from "react-hook-form";
import { ContractFormData } from "../interfaces/contract.interface";
import { ErrorMessage, Label, LabelGroup } from "../input/ContractInput";
import { countryList } from "../../utils/utils";

const CountryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const CountryButtonList = styled.div`
  display: flex;
  width: 100%;
  gap: 0.8rem;
  flex-wrap: wrap;
`;

const colorStyle = {
  selected: css`
    background-color: var(--purple-300);
    color: var(--gray-050);
    border: 0.1rem solid var(--purple-300);
  `,
  default: css`
    background-color: var(--white);
    color: var(--gray-400);
    border: 0.1rem solid var(--gray-400);
  `,
};

const CountryButton = styled.button<{ $status: boolean }>`
  padding: 0.2rem 1rem;
  border-radius: 1.2rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  ${({ $status }) => ($status ? colorStyle.selected : colorStyle.default)}
`;

interface AdvertisingCountryProps {
  setValue: UseFormSetValue<ContractFormData>;
  value?: string[];
  errorMessage?: string;
}

const AdvertisingCountry = ({
  value = [],
  setValue,
  errorMessage,
}: AdvertisingCountryProps) => {
  return (
    <CountryContainer>
      <LabelGroup>
        <Label $required={true}>Target countries</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <CountryButtonList>
        {countryList.map((data) => {
          const isSelected = value.includes(data.value);

          return (
            <CountryButton
              key={`country button ${data.id}`}
              $status={isSelected}
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setValue(
                  "country",
                  isSelected
                    ? value.filter((value) => value !== data.value)
                    : [...value, data.value]
                );
              }}
            >
              {data.label}
            </CountryButton>
          );
        })}
      </CountryButtonList>
    </CountryContainer>
  );
};

export default AdvertisingCountry;
