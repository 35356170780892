import React, { Fragment } from "react";
import { CelebData } from "../interfaces/celeb.interfaces";
import styled from "styled-components";
import FavoriteIcon from "@mui/icons-material/Favorite";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import {
  CardData,
  CardSubtitle,
  CardTitle,
  CountryColorSet,
  GridCard,
  ReportTitle,
} from "./ReportSection";
import { formatNumber, formatNumberWithCommas } from "../../utils/utils";
import AudienceGeoChart from "./AudienceGeoChart";
import AudienceGenderChart from "./AudienceGender";
import AudienceAgeChart from "./AudienceAgeChart";
import FollowersGrowthChart from "./FollowersGrowthChart";
import ImageContainer from "../common/ImageContainer";
import HelpMessage from "../icon/HelpMessage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15.8rem;
`;

const InstagramGridContainer = styled.div`
  position: relative;
  margin-top: 2rem;
  /* margin-left: 7.8rem; */
  display: grid;
  grid-template-rows: repeat(3, 24.4rem);
  grid-template-columns: repeat(5, 24.4rem);
  gap: 2rem;
  .instagram-thumbnail {
    grid-column: 1 / 3;
  }
  .audience-geo {
    grid-column: 2 / 4;
    .chart-list {
      display: flex;
    }
  }
  .instagram-summary {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    gap: 0.4rem;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
  }
  .follower-growth {
    grid-column: 2 / 6;
  }
`;

const InstagramThumbnail = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4.2rem 2.8rem;
  border: 0.1rem solid var(--gray-900);
  border-radius: 0.8rem;
  gap: 2rem;
`;

const InstagramThumbnailData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  gap: 1.6rem;
  .instagram-id {
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};
  }
  .celeb-name {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    color: var(--gray-600);
  }
  .border {
    border-top: 0.1rem solid black;
  }
  .instagram-data-list {
    display: flex;
    align-items: center;
    width: 100%;
    .instagram-data {
      text-align: center;
      width: 33.3%;
      .title {
        ${({ theme }) => theme.font.body2};
        ${({ theme }) => theme.font.regular};
      }
      .data {
        margin-top: 1.2rem;
        ${({ theme }) => theme.font.body1};
        ${({ theme }) => theme.font.bold};
      }
    }
  }
`;

const InstagramSummaryCard = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.2rem;
  position: relative;
  border: 0.1rem solid var(--gray-900);
  border-radius: 0.8rem;
`;

interface SimpleBarProps {
  $width: number;
  $bgColor: string;
}

const emvScoreColors: { [key: string]: string } = {
  Excellent: "#860CF9",
  Very_Good: "#1D5AD2",
  Good: "#2D9EFF",
  Average: "#F59E0B",
  Fair: "#EF3A74",
  Poor: "#B10052",
};

const SimpleBarChart = styled.div`
  display: flex;
  margin: 0 auto;
  width: 46.4rem;
  height: 2rem;
  border-radius: 0.4rem;
  background-color: var(--gray-300);
  overflow: hidden;
`;

const SimpleBar = styled.div<SimpleBarProps>`
  width: ${(props) => props.$width}%;
  background-color: ${(props) => props.$bgColor};
  position: relative;
`;

const DonughtChartList = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BarInfo = styled.div`
  display: none;
  background-color: var(--gray-050);
  border: 1px solid var(--gray-500);
  justify-content: center;
  &.bar-info {
  }
  &:hover {
    display: flex;
  }
`;

interface InstagramReportProps {
  celebData: CelebData;
}
const InstagramReport = ({ celebData }: InstagramReportProps) => {
  const instagramData = celebData.instagram;
  const instagramFollowers = formatNumber(instagramData.followers);
  const emvScore = celebData.celebInfo.emvScore;
  const emvScoreColor = emvScoreColors[emvScore] || "#000000";

  return (
    <>
      <Container>
        <ReportTitle>Instagram</ReportTitle>
        <InstagramGridContainer>
          <InstagramThumbnail className="instagram-thumbnail">
            <ImageContainer width={16} height={16} borderRadius={0.4}>
              <img src={instagramData.celebMainImg} alt="hel" />
            </ImageContainer>
            <InstagramThumbnailData>
              <div className="instagram-id">{instagramData.instagramName}</div>
              <div className="celeb-name">{celebData.celebInfo.name}</div>
              <div className="border"></div>
              <div className="instagram-data-list">
                <div className="instagram-data">
                  <div className="title">Posts</div>
                  <div className="data">
                    {formatNumber(instagramData.avgLikes)}
                  </div>
                </div>
                <div className="instagram-data">
                  <div className="title">Followers</div>
                  <div className="data">
                    {formatNumber(instagramData.followers)}
                  </div>
                </div>
                <div className="instagram-data">
                  <div className="title">Followings</div>
                  <div className="data">
                    {formatNumber(instagramData.followings)}
                  </div>
                </div>
              </div>
            </InstagramThumbnailData>
          </InstagramThumbnail>
          <GridCard>
            <div>
              <CardTitle>Followers</CardTitle>
              <CardSubtitle>Estimated Data</CardSubtitle>
            </div>

            <CardData>
              {instagramFollowers}
              {/*<span className="evaluation">*/}
              {/*  {celebData.celebInfo.followersScore}*/}
              {/*</span>*/}
            </CardData>
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>
                EGR
                <HelpMessage>
                  Engagement rate, which shows the percent of the audience who
                  engages with the content posted by this influencer, including
                  likes and comments.
                </HelpMessage>
              </CardTitle>
              <CardSubtitle>Estimated Data</CardSubtitle>
            </div>
            <CardData>
              {instagramData.egr.toFixed(1)}%
              {/*TODO: have to refactoring after sprint*/}
              {/*<span className="evaluation">{celebData.celebInfo.egrScore}</span>*/}
            </CardData>
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>
                EMV
                <HelpMessage>
                  Estimated cost to gain the same reach through paid social ad
                  with similar audience
                </HelpMessage>
              </CardTitle>
              <CardSubtitle>Estimated Data</CardSubtitle>
            </div>
            <CardData $backgroundColor={emvScoreColor}>
              ${formatNumberWithCommas(instagramData.emvFrom)}-<br />$
              {formatNumberWithCommas(instagramData.emvTo)}
              <span className="evaluation">{emvScore}</span>
            </CardData>
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>
                Real Followers
                <HelpMessage>
                  Real followers consists of real people and influencers,
                  excluding mass followers and suspicious accounts
                </HelpMessage>
              </CardTitle>
              <CardSubtitle>Estimated Data</CardSubtitle>
            </div>
            <CardData>
              {instagramData.realFollowers}%
              <span className="sub-text">{instagramFollowers}</span>
            </CardData>
          </GridCard>
          <GridCard className="audience-geo">
            <div>
              <CardTitle>
                Audience Geo
                <HelpMessage>
                  Audience Geography, which shows where the majority of the
                  audience lives
                </HelpMessage>
              </CardTitle>
            </div>
            <SimpleBarChart>
              {instagramData.audienceGeo.map((geo, index) => {
                return (
                  <Fragment key={"barchart" + geo.code + index}>
                    <SimpleBar
                      $width={geo.value}
                      $bgColor={CountryColorSet[index]}
                    ></SimpleBar>
                    <BarInfo />
                  </Fragment>
                );
              })}
            </SimpleBarChart>
            <DonughtChartList>
              {instagramData.audienceGeo.map((geo, index) => {
                return (
                  <AudienceGeoChart
                    key={"doughnutchart" + geo.code + index}
                    name={geo.name}
                    value={geo.value}
                    color={CountryColorSet[index]}
                  />
                );
              })}
            </DonughtChartList>
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>Audience Gender</CardTitle>
            </div>
            <AudienceGenderChart data={instagramData.audienceGender} />
          </GridCard>
          <GridCard>
            <div>
              <CardTitle>Audience Age</CardTitle>
              <AudienceAgeChart data={instagramData.audienceAge} />
            </div>
          </GridCard>
          <div className="instagram-summary">
            <InstagramSummaryCard>
              <CardTitle>
                <FavoriteIcon style={{ color: "#EC0E5D" }} />
                Avg. Likes
              </CardTitle>
              <CardData>{formatNumber(instagramData.avgLikes)}</CardData>
            </InstagramSummaryCard>
            <InstagramSummaryCard>
              <CardTitle>
                <ChatBubbleIcon style={{ color: "#F59E0B" }} />
                Avg. Comments
              </CardTitle>
              <CardData>{formatNumber(instagramData.avgComments)}</CardData>
            </InstagramSummaryCard>
            <InstagramSummaryCard>
              <CardTitle>
                <VisibilityIcon style={{ color: "#008DFF" }} />
                Avg. Impressions
              </CardTitle>
              <CardData>{formatNumber(instagramData.impressions)}</CardData>
            </InstagramSummaryCard>
          </div>
          <GridCard className="follower-growth">
            <div>
              <CardTitle>Followers Growth</CardTitle>
              <FollowersGrowthChart data={instagramData.followersChart} />
            </div>
          </GridCard>
        </InstagramGridContainer>
      </Container>
    </>
  );
};

export default InstagramReport;
