import { AxiosError } from "axios";
import {
  postAdContent,
  uploadAdContent,
  uploadAdContentList,
} from "../api/upload";
import { useMutation } from "@tanstack/react-query";
import {
  FileListForm,
  FileListUploadSuccess,
  FileUploadSuccessResponse,
} from "../components/interfaces/file.intrefaces";

interface ContentUploadOptions {
  onSuccess?: (response: FileUploadSuccessResponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useContentUpload = (options?: ContentUploadOptions) => {
  const uploadMutation = useMutation<
    FileUploadSuccessResponse,
    AxiosError,
    FormData
  >({
    mutationFn: (data: FormData) => uploadAdContent(data),
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const uploadFile = (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    uploadMutation.mutate(formData);
  };

  return {
    uploadFile,
    isLoading: uploadMutation.isPending,
    isError: uploadMutation.isError,
    error: uploadMutation.error,
  };
};

interface ContentUploadListOptions {
  onSuccess?: (response: FileListUploadSuccess) => void;
  onError?: (error: AxiosError) => void;
}

export const useContentListUpload = (options?: ContentUploadListOptions) => {
  const uploadListMutation = useMutation<
    FileListUploadSuccess,
    AxiosError,
    FileListForm
  >({
    mutationFn: (data: FileListForm) => postAdContent(data),
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const uploadContentList = (data: FileListForm) => {
    uploadListMutation.mutate(data);
  };

  return {
    uploadContentList,
    isPending: uploadListMutation.isPending,
    error: uploadListMutation.error,
    isError: uploadListMutation.isError,
    isSuccess: uploadListMutation.isSuccess,
  };
};
