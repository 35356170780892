import React, { useRef } from "react";
import styled from "styled-components";
import {
  useContentListUpload,
  useContentUpload,
} from "../../hooks/useContentUpload";

const AddNewMediaButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 44rem;
  cursor: pointer;
  &.add {
    color: var(--gray-600);
    text-align: center;
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    ${({ theme }) => theme.font.underline};
    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/plus.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

interface AddNewSlotProps {
  contractId: string;
  refetch: () => void;
}

const AddNewSlot = ({ contractId, refetch }: AddNewSlotProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { uploadContentList } = useContentListUpload();
  const { uploadFile } = useContentUpload({
    onSuccess: (response) => {
      uploadContentList({
        contractId: contractId,
        advertisingProductImgs: [response.data.imgUrl],
      });
      refetch();
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      uploadFile(file);
      e.target.value = "";
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <>
      <AddNewMediaButton
        className="add"
        type="button"
        onClick={handleUploadClick}
      >
        add new media
      </AddNewMediaButton>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".png, .jpeg, .jpg" // 허용할 파일 확장자
        style={{ display: "none" }}
      />
    </>
  );
};

export default AddNewSlot;
