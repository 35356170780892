import React from "react";
import { CelebData } from "../../interfaces/celeb.interfaces";
import Section from "../../layout/Section";
import styled from "styled-components";
import DataForSeoCard, { InstagramCard } from "./DataCard";
import NewsCard from "./NewsCard";

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
`;

interface CelebSummarySectionProps {
  celebData: CelebData;
}

const CelebSummarySection = ({ celebData }: CelebSummarySectionProps) => {
  const searchTrend = celebData.searchTrend;
  const searchTrendStatus = Object.keys(searchTrend).length !== 0;
  const instagram = celebData.instagram;
  const instagramStatus = Object.keys(instagram).length !== 0;
  const newsList = celebData.celebInfo.celebNews;
  const newsListStatus = celebData.celebInfo.celebNews.length !== 0;

  return (
    <Section mt={8}>
      <Wrapper>
        {newsListStatus && (
          <NewsCard
            newsList={newsList}
            dataStatus={searchTrendStatus && instagramStatus}
          />
        )}
        {searchTrendStatus && instagramStatus && (
          <>
            <DataForSeoCard searchTrend={searchTrend} />
            <InstagramCard instagram={instagram} />
          </>
        )}
      </Wrapper>
    </Section>
  );
};

export default CelebSummarySection;
