import { AxiosError } from "axios";
import { postSignImage, uploadSignImage } from "../api/upload";
import { useMutation } from "@tanstack/react-query";
import { SignUploadSuccessResponse } from "../components/interfaces/file.intrefaces";
import {
  SignImageForm,
  SignImageFormSuccessRepsponse,
} from "../components/interfaces/contract.interface";

interface SignFileUploadOptions {
  onSuccess?: (response: SignUploadSuccessResponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useSignFileUpload = (options?: SignFileUploadOptions) => {
  const mutation = useMutation<SignUploadSuccessResponse, AxiosError, FormData>(
    {
      mutationFn: (data: FormData) => uploadSignImage(data),
      onSuccess: (response) => {
        options?.onSuccess?.(response);
      },
      onError: (error) => {
        options?.onError?.(error);
      },
    }
  );

  const uploadFile = (config: FormData) => {
    mutation.mutate(config);
  };

  return {
    uploadFile,
    isPending: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};

interface SignFilePostOptions {
  onSuccess?: (response: SignImageFormSuccessRepsponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useSignFilePostUpload = (options?: SignFilePostOptions) => {
  const formMutation = useMutation<
    SignImageFormSuccessRepsponse,
    AxiosError,
    SignImageForm
  >({
    mutationFn: (data: SignImageForm) => postSignImage(data),
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const postSign = (form: SignImageForm) => {
    formMutation.mutate(form);
  };
  return {
    postSign,
    isPending: formMutation.isPending,
    error: formMutation.error,
    isError: formMutation.isError,
    isSuccess: formMutation.isSuccess,
  };
};
