import React from "react";
import styled from "styled-components";
import { RegisterOptions, UseFormRegister } from "react-hook-form";
import { LabelGroup, Label, ErrorMessage } from "./RegisterInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const TextArea = styled.textarea`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  font-family: "Inter";
  background-color: var(--white);
  color: var(--gray-900);
  border: 0.1rem solid var(--gray-300);
  border-radius: 0.4rem;
  height: 12rem;
  width: 42rem;
  padding: 2rem;
  resize: none;
  outline: none;
`;

interface RegisterTextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  fieldName: string;
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions<any>;
  label?: string;
  errorMessage?: string;
  required?: boolean;
}

const RegisterTextArea = ({
  fieldName,
  register,
  registerOptions = {},
  label,
  errorMessage,
  required = false,
  ...options
}: RegisterTextAreaProps) => {
  return (
    <Container>
      <LabelGroup>
        <Label $required={required}>{label}</Label>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </LabelGroup>
      <TextArea {...options} {...register(fieldName)} />
    </Container>
  );
};

export default RegisterTextArea;
