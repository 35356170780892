import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAtomValue } from "jotai";
import { userDataAtom } from "../atoms/auth";

interface RouteProtectProps {
  children: JSX.Element;
}

const RouteProtect = ({ children }: RouteProtectProps) => {
  const userData = useAtomValue(userDataAtom);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userData !== undefined) {
      setIsLoading(false); // 로딩 완료
    }
  }, [userData]);

  if (isLoading) {
    return <div>Loading...</div>; // 초기 상태 로딩 화면
  }

  return userData?.id ? children : <Navigate to="/login" replace />;
};

export default RouteProtect;
