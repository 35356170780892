import React from "react";
import styled from "styled-components";
import { bottomBox } from "./commonStyle";
import { useModal } from "../common/ModalUtil";
import { useContractStatus } from "../../hooks/useContractStatus";
import { CONTRACT_STATUS_PRESETS } from "../../configs/contractStatus";
import { MODAL_TYPES } from "../../atoms/modal";

const AdvertiserContainer = styled.div`
  color: var(--gray-500);
  text-align: right;
  margin-top: 4rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.underline};
  cursor: pointer;
`;

interface RequestStatusProps {
  contractId: string;
  refetch: () => void;
}

export const StepRequestAdvertiser = ({
  contractId,
  refetch,
}: RequestStatusProps) => {
  const { showModal } = useModal(MODAL_TYPES.CANCEL_CONTRACT);

  const onClickReject = () => {
    showModal({ contractId: contractId, refetch: refetch });
  };
  return (
    <AdvertiserContainer onClick={onClickReject}>
      Contract Cancellation
    </AdvertiserContainer>
  );
};

const CelebrityAgencyContainer = styled.div`
  ${bottomBox}
  display: flex;
  justify-content: center;
  padding-top: 4rem;
  gap: 6.4rem;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  > .reject {
    cursor: pointer;
    display: flex;
    color: var(--gray-600);
    &::before {
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/contract_delete.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 0.4rem;
    }
  }
  > .confirm {
    cursor: pointer;
    display: flex;
    color: var(--gray-900);
    &::before {
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/contract_edit.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      margin-right: 0.4rem;
    }
  }
`;

export const StepRequestCelebAgency = ({
  contractId,
  refetch,
}: RequestStatusProps) => {
  const { showModal } = useModal(MODAL_TYPES.CANCEL_CONTRACT);

  const { updateStatus } = useContractStatus(contractId, {
    onSuccess: () => {
      refetch();
    },
  });

  const onClickReject = () => {
    showModal({ contractId: contractId, refetch: refetch });
  };

  return (
    <CelebrityAgencyContainer>
      <div className="reject" onClick={onClickReject}>
        Reject
      </div>
      <div
        className="confirm"
        onClick={() => updateStatus(CONTRACT_STATUS_PRESETS.NEGOTIATION)}
      >
        Negotiation
      </div>
    </CelebrityAgencyContainer>
  );
};
