import React from "react";
import styled from "styled-components";
import formatDate from "../../utils/utils";
import { useProject } from "../../hooks/useProject";
import { Navigate, useSearchParams } from "react-router-dom";
import ContractList from "./advertiser/ContractList";
import Breadcrumb from "../common/Breadcrumb";
import { Flex, FlexColumn } from "./TableFlex";

const ProjectTitle = styled.div`
  margin: 2rem 4rem 0;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.bold};
  .create-at {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const ProjectInfo = styled.div`
  width: 100%;
  padding: 4rem;
  border: 0.1rem solid var(--gray-300);
  background-color: var(--white);
  margin: 1.6rem auto 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Delete = styled.button`
  display: flex;
  justify-content: right;
  align-items: center;
  color: var(--gray-600);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  ${({ theme }) => theme.font.underline};
  cursor: pointer;
  &::before {
    content: "";
    width: 2.4rem;
    height: 2.4rem;
    display: inline-block;
    background-image: url("/svg/trash_can.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`;

const ProjectDetail = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("projectId");
  const { data, isPending } = useProject(id || undefined);

  if (!id) return <Navigate to="../list?type=ongoing" />;
  if (isPending) return <></>;

  return (
    <>
      <Breadcrumb list={[{ label: "Projects", url: "../list?type=ongoing" }]} />
      <ProjectTitle>
        {data.data.projectName}
        <span className="create-at">{formatDate(data.data.createdAt)}</span>
      </ProjectTitle>
      <ProjectInfo>
        <Delete>Delete</Delete>
        <Flex>
          <div className="title">Budget</div>
          <div className="value">{data.data.budget}</div>
        </Flex>
        <Flex>
          <div className="title">Period</div>
          <div className="value">
            {data.data.startDate}-{data.data.endDate}
            {/*{formatDate(data.startDate)}-{formatDate(data.startDate)}*/}
          </div>
        </Flex>
        <FlexColumn>
          <div className="title">Description</div>
          <div className="value">{data.data.description}</div>
        </FlexColumn>
      </ProjectInfo>
      <ContractList contractList={data.data.contracts} />
    </>
  );
};

export default ProjectDetail;
