import React from "react";
import { Outlet } from "react-router-dom";
import { FormContainer } from "../../common/FormContainer";
import Logo from "../../layout/Logo";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
`;

const SignupLayout = () => {
  return (
    <Container>
      <Logo url="/" />
      <FormContainer>
        <Outlet />
      </FormContainer>
    </Container>
  );
};

export default SignupLayout;
