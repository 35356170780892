import React from "react";
import {
  ProjectData,
  ProjectStatusList,
} from "../../interfaces/project.interface";
import { useQuery } from "@tanstack/react-query";
import { getProjectList } from "../../../api/project";
import styled from "styled-components";
import CheckButton from "../../button/CheckButton";
import StyledButton from "../../button/StyledButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { contractFormAtom } from "../../../atoms/contract";
import { FormContainer, StepDescription } from "../../common/FormContainer";

const ProjectListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Project = styled.div`
  width: 42rem;
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 2.6rem 4rem;
  border-radius: 0.4rem;
  border: 0.2rem solid transparent;
  background: var(--white);
  justify-content: center;
  cursor: pointer;
  .title {
    margin-left: 2rem;
    width: 16rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date {
    width: 10rem;
    overflow: hidden;
    margin-left: auto;
    white-space: nowrap;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
  > .add-project {
    width: 4rem;
    height: 4rem;
    background-image: url("/svg/contract/plus.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 4rem;
  margin-top: 3rem;
`;

const SelectProject = () => {
  const { data, isPending } = useQuery<ProjectStatusList>({
    queryKey: ["ProjectList"],
    queryFn: () => getProjectList(),
  });

  const [contractForm, setContractForm] = useAtom(contractFormAtom);
  const navigate = useNavigate();

  if (isPending) return <></>;
  if (!data) return <></>;
  if (!contractForm.contractType) return <Navigate to="../" />;

  const handleProjectSelect = (projectData: ProjectData) => {
    setContractForm({
      ...contractForm,
      projectData:
        projectData === contractForm.projectData ? undefined : projectData,
    });
  };

  const onClickNext = () => {
    navigate("../detail");
  };

  return (
    <FormContainer>
      <StepDescription step={2} length={5}>
        Select the project that matches the contract.
      </StepDescription>
      <ProjectListContainer>
        {data.data.ongoingProjects.map((project) => {
          const date = new Date(project.createdAt);
          const formatDate = date
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
            })
            .replace(",", ".");

          return (
            <Project
              key={project.id}
              onClick={() => handleProjectSelect(project)}
            >
              <CheckButton
                status={contractForm.projectData === project}
                setStatus={() => handleProjectSelect(project)}
              />
              <span className="title">{project.title}</span>
              <span className="date">{formatDate}</span>
            </Project>
          );
        })}
        <Project>
          <button
            type="button"
            className="add-project"
            onClick={() => navigate("create")}
          />
        </Project>
        <ButtonContainer>
          <StyledButton
            label="Previous"
            size="sm"
            color="white"
            onClick={() => navigate(-1)}
          />
          <StyledButton
            label="Next"
            size="sm"
            color="black"
            disabled={!contractForm.projectData}
            onClick={onClickNext}
          />
        </ButtonContainer>
      </ProjectListContainer>
    </FormContainer>
  );
};

export default SelectProject;
