import axios from "axios";
import { CelebListFilterQuery } from "../components/interfaces/filter.interface";
import { privateClient } from "./axiosInstance";

export const getCelebList = async (query: CelebListFilterQuery) => {
  const filteredParams = Object.fromEntries(
    Object.entries(query).filter(
      ([, value]) =>
        value !== "" && value !== null && value !== undefined && value !== 0
    )
  );

  const response = await privateClient.get("/api/v1/celeb", {
    params: filteredParams,
  });

  return response.data;
};

export const getFavoriteCelebList = async () => {
  const response = await privateClient.get("/api/v1/celeb", {
    params: {
      isLike: true,
    },
  });

  return response.data;
};

export const getCeleb = async (id: string | undefined) => {
  if (!id) return null;
  const response = await privateClient.get("/api/v1/celeb/" + id);

  return response.data;
};

export const getCelebHighlight = async (id: string | undefined) => {
  const response = await privateClient.get(`/api/v1/celeb/${id}/highlights`);

  return response.data;
};

export const getCelebInContract = async () => {
  const response = await privateClient.get("/api/v1/contract/celeb_only");

  return response.data;
};

export const getCelebOnCelevu = async () => {
  const response = await privateClient.get("/api/v1/celeb/agency-only");

  return response.data;
};
