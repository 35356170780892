import { atom, useAtom } from "jotai";
import styled from "styled-components";
import { useRef } from "react";
import Fade from "./Fade";
import { ModalType } from "../../atoms/modal";

interface ModalState {
  status: boolean;
  type: string | null;
  data?: Record<string, any>;
}

const initialModalState: Record<string, ModalState> = {};
export const modalAtom = atom(initialModalState);

export const useModal = (type: ModalType) => {
  const [modals, setModals] = useAtom(modalAtom);

  const showModal = (data?: Record<string, any>) => {
    setModals((prev) => ({
      ...prev,
      [type]: {
        status: true,
        type,
        data: data ? { ...data, status: true } : { status: true },
      },
    }));
  };

  const hideModal = () => {
    setModals((prev) => ({
      ...prev,
      [type]: {
        status: false,
        type,
        data: { status: false },
      },
    }));
  };

  return {
    showModal,
    hideModal,
    modalState: modals[type],
  };
};

interface ModalContentProps {
  $width: number;
  $height?: number;
  $padding: number;
  $borderRadius: number;
  $unit: string;
}

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.5rem);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: auto;
`;

const ModalContent = styled.div<ModalContentProps>`
  border-radius: ${({ $borderRadius }) => $borderRadius}${({ $unit }) => $unit};
  width: ${({ $width }) => $width}${({ $unit }) => $unit};
  height: ${({ $height }) => ($height ? $height : "auto")}${({ $height, $unit }) => ($height ? $unit : "")};
  padding: ${({ $padding }) => $padding}${({ $unit }) => $unit};
  background-color: #fbfbfb;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0rem 0rem 1.5rem 0rem var(--gray-300);
  overflow: auto;
  /*i.material-icons {
    position: absolute;
    color: #666666;
    width: 28px;
    height: 28px;
    right: 28px;
    top: 28px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }*/
`;

interface ModalFrameProps {
  children: React.ReactNode;
  width: number;
  height?: number;
  unit?: string;
  padding?: number;
  borderRadius?: number;
  modalType: ModalType;
}

export const ModalFrame = ({
  children,
  width,
  height,
  padding = 2,
  borderRadius = 0,
  unit = "rem",
  modalType,
}: ModalFrameProps) => {
  const [modals] = useAtom(modalAtom);
  const modalState = modals[modalType];
  const { hideModal } = useModal(modalType);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleClickBackground = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === modalRef.current) {
      hideModal();
    }
  };

  if (!modalState) {
    return null;
  }

  return (
    <Fade status={modalState.status} time={100}>
      <ModalBackground ref={modalRef} onClick={handleClickBackground}>
        <ModalContent
          $width={width}
          $height={height}
          $padding={padding}
          $borderRadius={borderRadius}
          $unit={unit}
        >
          {children}
        </ModalContent>
      </ModalBackground>
    </Fade>
  );
};
