import React from "react";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { getProjectList } from "../../api/project";

import StyledButton from "../button/StyledButton";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  ProjectData,
  ProjectStatusList,
} from "../interfaces/project.interface";

const ProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 128rem;
  margin: 0 auto;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  margin-top: 6.8rem;
`;

const Status = styled(Link)`
  color: var(--gray-900);

  ${({ theme }) => theme.font.title1};
  &.active {
    ${({ theme }) => theme.font.bold};
  }
`;

export interface ProjectContext {
  ongoingProjects: ProjectData[];
  pastProjects: ProjectData[];
}

const ProjectContent = () => {
  const { data, isPending } = useQuery<ProjectStatusList>({
    queryKey: ["DashboardProjectList"],
    queryFn: () => getProjectList(),
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isOngoing = searchParams.get("type") === "ongoing";
  const isPast = searchParams.get("type") === "past";
  const showStatus = isOngoing || isPast;

  if (isPending) return <div></div>;
  if (!data) return <></>;

  return (
    <ProjectContainer>
      {showStatus && (
        <StatusContainer>
          <Status to="?type=ongoing" className={isOngoing ? "active" : ""}>
            Ongoing project ({data.data.ongoingProjects.length})
          </Status>
          |
          <Status to="?type=past" className={isPast ? "active" : ""}>
            Past project ({data.data.pastProjects.length})
          </Status>
        </StatusContainer>
      )}
      <Outlet
        context={{
          ongoingProjects: data.data.ongoingProjects,
          pastProjects: data.data.pastProjects,
        }}
      />
      {showStatus && (
        <StyledButton
          label="Create new project"
          margin="7.4rem auto"
          onClick={() => navigate("create")}
        />
      )}
    </ProjectContainer>
  );
};

export default ProjectContent;
