import React, { useRef, useState } from "react";
import styled from "styled-components";
import { ContractData } from "../interfaces/contract.interface";
import { ContentBox } from "./ContentBox";
import StyledButton from "../button/StyledButton";
import { useContractStatus } from "../../hooks/useContractStatus";
import { CONTRACT_STATUS_PRESETS } from "../../configs/contractStatus";
import {
  useContentListUpload,
  useContentUpload,
} from "../../hooks/useContentUpload";

const FirstUploadBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  text-align: center;
  padding: 2rem 0;
  > .description {
    color: var(--gray-600);
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
  }
  > .upload-media {
    color: var(--gray-900);
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    ${({ theme }) => theme.font.underline};
    cursor: pointer;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 20rem);
  gap: 1rem;
`;

const ImageBox = styled.div`
  position: relative;
  width: 20rem;
  height: 20rem;
  background-color: var(--gray-300);
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .cancel-button {
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    width: 2.4rem;
    height: 2.4rem;
    background-image: url("/svg/cancel_circle.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &.add {
    cursor: pointer;
    background-color: white;
    align-content: center;
    text-align: center;
    > button {
      width: 100%;
      height: 100%;
      background-image: url("/svg/plus.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 4rem;
      &:hover {
        background-color: var(--gray-300);
      }
    }
  }
`;

const CompleteButtonContainer = styled.div`
  padding-top: 8rem;
  margin: 0 auto;
  border-top: 0.4rem solid var(--gray-900);
  width: 42rem;
`;

interface ContentUploadProps {
  contractData: ContractData;
  refetch: () => void;
}

const ContentUpload = ({ contractData, refetch }: ContentUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [showStatus, setShowStatus] = useState(false);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const { updateStatus } = useContractStatus(contractData.contractId, {
    onSuccess: () => {
      refetch();
    },
  });

  const { uploadContentList } = useContentListUpload();

  const { uploadFile, isLoading } = useContentUpload({
    onSuccess: (response) => {
      setUploadedUrls((prev) => [...prev, response.data.imgUrl]);
    },
    onError: (error) => {
      console.error("콘텐츠 업로드 실패:", error);
    },
  });

  const onClickPost = () => {
    uploadContentList({
      contractId: contractData.contractId,
      advertisingProductImgs: uploadedUrls,
    });
    if (uploadedUrls.length > 0) {
      updateStatus(CONTRACT_STATUS_PRESETS.WATING_REVIEW);
    }
  };

  const handleUploadClick = () => {
    setShowStatus(true);
    fileInputRef.current?.click();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      Array.from(e.target.files).forEach((file) => {
        uploadFile(file);
      });
      e.target.value = "";
    }
  };

  const handleRemoveFile = (urlToRemove: string) => {
    setUploadedUrls((prev) => prev.filter((url) => url !== urlToRemove));
  };

  //const handleCompleteUpload = () => {
  //  if (uploadedUrls.length > 0) {
  //    updateStatus(CONTRACT_STATUS_PRESETS.WATING_REVIEW);
  //  }
  //};

  return (
    <ContentBox title="Content Using Stock Images">
      {!showStatus ? (
        <>
          <FirstUploadBox>
            <div className="description">Please upload the ad drafts.</div>
            <div className="upload-media" onClick={handleUploadClick}>
              Upload media
            </div>
          </FirstUploadBox>
          <CompleteButtonContainer>
            <StyledButton
              label="Upload"
              size="lg"
              onClick={handleUploadClick}
            />
          </CompleteButtonContainer>
        </>
      ) : (
        <>
          <ImageGrid>
            {uploadedUrls.map((url) => (
              <ImageBox key={url}>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => handleRemoveFile(url)}
                />
                <img src={url} alt="uploaded content" />
              </ImageBox>
            ))}
            <ImageBox className="add">
              <button
                type="button"
                onClick={handleUploadClick}
                disabled={isLoading}
              />
            </ImageBox>
          </ImageGrid>
          <CompleteButtonContainer>
            <StyledButton
              label="Upload complete"
              size="lg"
              type="button"
              onClick={onClickPost}
              disabled={isLoading || uploadedUrls.length === 0}
            />
          </CompleteButtonContainer>
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".png, .jpeg, .jpg" // 허용할 파일 확장자
        style={{ display: "none" }}
      />
    </ContentBox>
  );
};

export default ContentUpload;
