import { UseFormSetError } from "react-hook-form";
import {
  SignupErrorResponse,
  SignupFormData,
} from "../components/interfaces/auth.interfaces";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { postSignup } from "../api/Signup";
import { useLogin } from "./useLogin";

const isFieldInSignupFormData = (
  field: string
): field is keyof SignupFormData => {
  return [
    "email",
    "password",
    "passwordConfirm",
    "phone",
    "companyName",
    "position",
  ].includes(field);
};

export const useSignupMutation = (
  setError: UseFormSetError<SignupFormData>
) => {
  const { login } = useLogin();
  return useMutation<
    AxiosResponse,
    AxiosError<SignupErrorResponse>,
    SignupFormData
  >({
    mutationFn: postSignup,
    onSuccess: async (data, variables) => {
      try {
        await login({
          email: variables.email,
          password: variables.password,
        });
      } catch (error) {
        setError("email", {
          type: "server",
          message: "Login failed after signup. Please try logging in manually.",
        });
      }
    },
    onError: (error) => {
      const response = error.response?.data;
      if (response?.message) {
        response.message.forEach((err) => {
          if (isFieldInSignupFormData(err.field)) {
            setError(err.field, {
              type: "server",
              message: err.messages[0], // 에러 메시지 처리
            });
          }
        });
      } else {
        setError("email", {
          type: "server",
          message: "An unexpected error occurred. Please try again.",
        });
      }
    },
  });
};
