import React from "react";
import {
  Link,
  Outlet,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import { useCelebInContract } from "../../../hooks/useCeleb";
import { CelebAgencyContract } from "../../interfaces/celeb.interfaces";

const CelebContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 128rem;
  margin: 0 auto;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2.4rem;
  margin-top: 6.8rem;
`;

const Status = styled(Link)`
  color: var(--gray-900);

  ${({ theme }) => theme.font.title1};
  &.active {
    ${({ theme }) => theme.font.bold};
  }
`;

export interface ContractContext {
  activeContracts: CelebAgencyContract[];
  inactiveContracts: CelebAgencyContract[];
}

const CelebContent = () => {
  const [searchParams] = useSearchParams();
  const { data, isPending } = useCelebInContract();
  const isActive = searchParams.get("type") === "active";
  const isInactive = searchParams.get("type") === "inactive";
  const showStatus = isActive || isInactive;

  if (isPending) return <div>Loading</div>;
  if (!data) return <></>;

  return (
    <CelebContainer>
      {showStatus && (
        <StatusContainer>
          <Status to="?type=active" className={isActive ? "active" : ""}>
            Active contract ({data.data.activeContracts.length})
          </Status>
          |
          <Status to="?type=inactive" className={isInactive ? "active" : ""}>
            Inactive contract ({data.data.inactiveContracts.length})
          </Status>
        </StatusContainer>
      )}
      <Outlet
        context={{
          activeContracts: data.data.activeContracts,
          inactiveContracts: data.data.inactiveContracts,
        }}
      />
    </CelebContainer>
  );
};

export default CelebContent;
