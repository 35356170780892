import {
  ContractFormData,
  ContractStatusBody,
  PostCommentForm,
} from "../components/interfaces/contract.interface";
import { privateClient } from "./axiosInstance";

export const postComment = async (data: PostCommentForm) => {
  const response = await privateClient.post("/api/v1/comment", data);

  return response.data;
};

export const postAdvertiseComment = async (data: PostCommentForm) => {
  const response = await privateClient.post(
    "/api/v1/advertising_product_comment",
    data
  );

  return response.data;
};

export const cancelContract = async (
  contractId: string,
  cancelReason: string
) => {
  const response = await privateClient.put(
    `/api/v1/contract/${contractId}/cancel`,
    {
      cancelReason: cancelReason,
    }
  );

  return response.data;
};

export const createContract = async (data: ContractFormData) => {
  const response = await privateClient.post("/api/v1/contract", data);

  return response.data;
};

export const getContract = async (id: string | undefined) => {
  if (!id) {
    return null;
  }
  const response = await privateClient.get(`/api/v1/contract/${id}`);

  return response.data;
};

export const getContentList = async (id: string | undefined) => {
  if (!id) {
    return null;
  }
  const response = await privateClient.get(
    `/api/v1/advertising_product/${id}/contract`
  );

  return response.data;
};

export const putCancelContract = async (
  id: string | undefined,
  cancelReason: string
) => {
  if (!id) {
    return null;
  }
  const response = await privateClient.put(`/api/v1/contract/${id}/cancel`, {
    cancelReason: cancelReason,
  });

  return response.data;
};

export const getContractHistory = async (id: string | undefined) => {
  if (!id) {
    return null;
  }
  const response = await privateClient.get(`/api/v1/history/${id}`);

  return response.data;
};

export const putContractStatus = async (
  id: string | undefined,
  data: ContractStatusBody
) => {
  const response = await privateClient.put(
    `/api/v1/contract/${id}/status`,
    data
  );

  return response.data;
};

export const getContractSign = async (id: string | undefined) => {
  if (!id) {
    return null;
  }
  const response = await privateClient.get(`/api/v1/contract/${id}/sign`);

  return response.data;
};
