import { AdvertisingContentModifyForm } from "../components/interfaces/advertise.intreface";
import { privateClient } from "./axiosInstance";

export const putAdvertiseProductConfirm = async (id: string | undefined) => {
  if (!id) {
    return null;
  }
  const response = await privateClient.put(
    `/api/v1/advertising_product/${id}/confirm`
  );

  return response.data;
};

export const putAdvertiseProductReject = async (id: string | undefined) => {
  if (!id) {
    return null;
  }
  const response = await privateClient.put(
    `/api/v1/advertising_product/${id}/reject`
  );

  return response.data;
};

export const putAdvertiseProductModify = async (
  data: AdvertisingContentModifyForm
) => {
  const response = await privateClient.put(
    `/api/v1/advertising_product/${data.parentId}`,
    data
  );

  return response.data;
};

export const deleteAdvertiseProduct = async (id: string | undefined) => {
  if (!id) {
    return null;
  }
  const response = await privateClient.delete(
    `/api/v1/advertising_product/${id}`
  );

  return response.data;
};
