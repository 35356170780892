import React from "react";
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import formatDate from "../../../utils/utils";
import { ProjectContext } from "../ProjectContent";

const TableContainer = styled.ul`
  margin-top: 2.8rem;
  border-top: 0.5rem solid var(--gray-900);
  > :first-child {
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
    border-bottom: 0.1rem solid var(--gray-900);
    cursor: default;
  }
`;

const TableRow = styled.li`
  display: grid;
  align-items: center;
  grid-template-columns: 20rem 40rem 10rem 20rem 12rem;
  /*grid-template-columns: 2fr 4fr 1fr 2fr 1.2fr;*/
  padding: 2rem 2rem;
  height: 6rem;
  text-align: center;
  border-bottom: 0.1rem solid var(--gray-300);
  cursor: pointer;
  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ProjectListForAdvertiser = () => {
  const [searchParams] = useSearchParams();
  const { ongoingProjects, pastProjects } = useOutletContext<ProjectContext>();
  const navigate = useNavigate();

  const projectList =
    searchParams.get("type") === "ongoing" ? ongoingProjects : pastProjects;

  return (
    <>
      <TableContainer>
        <TableRow>
          <span>Project name</span>
          <span>Project description</span>
          <span>Budget</span>
          <span>Period</span>
          <span>Create date</span>
        </TableRow>
        {projectList.map((project) => (
          <TableRow
            key={project.id}
            onClick={() =>
              navigate(`/dashboard/projects/detail?projectId=${project.id}`)
            }
          >
            <span>{project.title}</span>
            <span>{project.description}</span>
            <span>{project.budget}</span>
            <span>
              {formatDate(project.startDate)} - {formatDate(project.endDate)}
            </span>
            <span>{formatDate(project.createdAt)}</span>
          </TableRow>
        ))}
      </TableContainer>
    </>
  );
};

export default ProjectListForAdvertiser;
