import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createContract,
  getContentList,
  getContract,
  getContractHistory,
  getContractSign,
} from "../api/contract";
import {
  ContentListSuccessResopnse,
  ContractDataResponse,
  ContractFormData,
  ContractFormDataSuccessResponse,
  ContractSignSuccessResponse,
} from "../components/interfaces/contract.interface";
import { AxiosError } from "axios";

export const useContract = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<ContractDataResponse>({
    queryKey: ["getProjectData", id],
    queryFn: () => getContract(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useContractSign = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<ContractSignSuccessResponse>({
    queryKey: ["getContractSign", id],
    queryFn: () => getContractSign(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useContractHistory = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<ContractDataResponse>({
    queryKey: ["getContractHistory", id],
    queryFn: () => getContractHistory(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};

interface useCreateContractOptions {
  onSuccess?: (response: ContractFormDataSuccessResponse) => void;
  onError?: (error: AxiosError) => void;
}

export const useCreateContract = (options?: useCreateContractOptions) => {
  const mutation = useMutation<
    ContractFormDataSuccessResponse,
    AxiosError,
    ContractFormData
  >({
    mutationFn: (data: ContractFormData) => createContract(data),
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const confirmContract = (data: ContractFormData) => {
    mutation.mutate(data);
  };

  return {
    confirmContract,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  };
};

export const useContentList = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<ContentListSuccessResopnse>({
    queryKey: ["getContentList", id],
    queryFn: () => getContentList(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};
