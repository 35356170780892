import React from "react";
import styled from "styled-components";
import Checkbox from "../common/Checkbox";
import Order from "../select/Order";
import { CelebListFilterQuery } from "../interfaces/filter.interface";

const FilterContainer = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  margin-top: 14.8rem;
  margin-left: auto;
`;

interface FilterProps {
  query: CelebListFilterQuery;
  setQuery: Function;
}

const Filter = ({ query, setQuery }: FilterProps) => {
  return (
    <FilterContainer>
      <Checkbox
        label="Male"
        value="MALE"
        checked={query.gender.includes("MALE")}
        onChange={(value, checked) => setQuery("gender", value, checked)}
      />
      <Checkbox
        label="Female"
        value="FEMALE"
        checked={query.gender.includes("FEMALE")}
        onChange={(value, checked) => setQuery("gender", value, checked)}
      />
      <Order query={query} setQuery={setQuery} />
    </FilterContainer>
  );
};

export default Filter;
