import React, { useEffect, useRef, useState } from "react";
import { ContentBox } from "./ContentBox";
import {
  AdvertiseingProductChild,
  AdvertisingProductImage,
} from "../interfaces/contract.interface";
import styled from "styled-components";
import ImageContainer from "../common/ImageContainer";
import AdvertiseCommentList from "./AdvertiseCommentList";
import { useContentList } from "../../hooks/useContract";
import {
  useAdvertiseProductDelete,
  useAdvertiseProductModify,
} from "../../hooks/useAdvertiseProduct";
import StyledButton from "../button/StyledButton";
import ContentSlot from "./ContentSlot";
import { useContentUpload } from "../../hooks/useContentUpload";
import AddNewSlot from "./AddNewSlot";
import { CONTRACT_STATUS_PRESETS } from "../../configs/contractStatus";
import { useContractStatus } from "../../hooks/useContractStatus";

const MainView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
  margin: 0 auto;
`;

const CommentContainer = styled.div`
  margin-top: 2rem;
  gap: 2rem;
  border-top: 0.4rem solid var(--black);
  padding-top: 6rem;
`;

const SubView = styled.div`
  position: absolute;
  right: 100%;
  top: 0;
  /*margin-right: 1.2rem;*/
`;

const SlotList = styled.div`
  padding-right: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  height: 42rem;
  overflow-y: scroll;
`;

const ContentConfirmButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 2rem;
  top: 1rem;
  right: 1rem;
  display: flex;
`;

const ContentConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  gap: 0.4rem;
  &.delete {
    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/cancel_circle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &.modify {
    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/modify.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

interface ContentModifyProps {
  contractId: string;
  subStatus: string | null;
  refetch: () => void;
}

interface SelectedContent {
  advertisingProduct: AdvertisingProductImage;
  selectedChild: AdvertiseingProductChild;
}

const ContentModify = ({
  contractId,
  subStatus,
  refetch,
}: ContentModifyProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { data, isPending, refetch: refetchList } = useContentList(contractId);
  const [selectedContent, setSelectedContent] = useState<SelectedContent>();
  const { modify } = useAdvertiseProductModify({
    onSuccess: () => {
      refetchList();
    },
  });
  const { deleteContent } = useAdvertiseProductDelete({
    onSuccess: () => {
      refetchList();
    },
  });

  const { updateStatus } = useContractStatus(contractId, {
    onSuccess: () => {
      refetch();
    },
  });

  const { uploadFile } = useContentUpload({
    onSuccess: (response) => {
      if (selectedContent?.advertisingProduct)
        modify({
          fileName: response.data.fileName,
          imgUrl: response.data.imgUrl,
          parentId: selectedContent.advertisingProduct.advertisingProductId,
        });
      refetchList();
    },
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      uploadFile(file);
      e.target.value = "";
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  useEffect(() => {
    if (data?.data.advertisingImgs.length) {
      const firstImage = data.data.advertisingImgs[0];
      if (
        !selectedContent?.advertisingProduct ||
        !data.data.advertisingImgs.find(
          (img) =>
            img.advertisingProductId ===
            selectedContent.advertisingProduct.advertisingProductId
        )
      ) {
        if (firstImage.children.length > 0) {
          setSelectedContent({
            advertisingProduct: firstImage,
            selectedChild: firstImage.children[0],
          });
        }
      }
    }
  }, [data, selectedContent]);

  if (isPending) return <>Loading</>;
  if (!data || !selectedContent) return <></>;

  const handleImageSelect = (
    image: AdvertisingProductImage,
    children: AdvertiseingProductChild
  ) => {
    setSelectedContent({
      advertisingProduct: image,
      selectedChild: children,
    });
  };

  const disabledStatus =
    subStatus === "UPLOAD_SIDE_WAITING_FOR_REVIEW/CONTENT_SIDE_REVIEW_REQUIRED"
      ? true
      : false;

  return (
    <ContentBox title="Content Using Stock Images Confirm">
      <MainView>
        <ImageContainer width={42} height={42}>
          <img src={selectedContent.selectedChild.imgUrl} alt="test" />
        </ImageContainer>
        {selectedContent.advertisingProduct.children[0].imgUrl ===
          selectedContent.selectedChild.imgUrl && (
          <ContentConfirmButtonContainer>
            <ContentConfirmButton
              className="delete"
              onClick={() =>
                deleteContent(
                  selectedContent.advertisingProduct.advertisingProductId
                )
              }
            >
              Delete
            </ContentConfirmButton>
            <ContentConfirmButton
              className="modify"
              onClick={() => handleUploadClick()}
            >
              Modify
            </ContentConfirmButton>
          </ContentConfirmButtonContainer>
        )}
        <AddNewSlot contractId={contractId} refetch={refetchList} />
        <CommentContainer>
          <AdvertiseCommentList
            advertisingProductId={
              selectedContent.advertisingProduct.advertisingProductId
            }
            commentList={selectedContent.advertisingProduct.comments}
            contractId={selectedContent.advertisingProduct.contractId}
            refetch={refetchList}
          />
        </CommentContainer>
        <SubView>
          <SlotList>
            {data.data.advertisingImgs.map((image) => (
              <ContentSlot
                key={image.advertisingProductId}
                onClickFn={handleImageSelect}
                productImage={image}
              />
            ))}
          </SlotList>
        </SubView>
        <StyledButton
          size="lg"
          color="black"
          label="Re-upload Complete"
          disabled={disabledStatus}
          onClick={() => updateStatus(CONTRACT_STATUS_PRESETS.WATING_REVIEW)}
        />
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept=".png, .jpeg, .jpg" // 허용할 파일 확장자
          style={{ display: "none" }}
        />
      </MainView>
    </ContentBox>
  );
};
export default ContentModify;
