import { useQuery } from "@tanstack/react-query";
import {
  CelebHighlightResponse,
  CelebInContractResponse,
  CelebListSuccessResponse,
  CelebOnCelevuSuccessResponse,
  CelebResponse,
} from "../components/interfaces/celeb.interfaces";
import {
  getCeleb,
  getCelebHighlight,
  getCelebInContract,
  getCelebList,
  getCelebOnCelevu,
} from "../api/celebList";
import { CelebListFilterQuery } from "../components/interfaces/filter.interface";

export const useCelebData = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery<CelebResponse>({
    queryKey: [id, "getCelebData"],
    queryFn: () => getCeleb(id),
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useCelebList = (query: CelebListFilterQuery) => {
  const { data, isPending, refetch } = useQuery<CelebListSuccessResponse>({
    queryKey: ["getCelebList"],
    queryFn: () => getCelebList(query),
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useCelebHighlight = (id: string) => {
  const { data, isPending, refetch } = useQuery<CelebHighlightResponse>({
    queryKey: ["getCelebHighlight", id],
    queryFn: () => getCelebHighlight(id),
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useCelebInContract = () => {
  const { data, isPending, refetch } = useQuery<CelebInContractResponse>({
    queryKey: ["getCelebInContract"],
    queryFn: () => getCelebInContract(),
  });

  return {
    data,
    isPending,
    refetch,
  };
};

export const useCelebOnCelevu = () => {
  const { data, isPending, refetch } = useQuery<CelebOnCelevuSuccessResponse>({
    queryKey: ["getCelebOnCelevu"],
    queryFn: () => getCelebOnCelevu(),
  });

  return {
    data,
    isPending,
    refetch,
  };
};
