import { atom } from "jotai";
import { CelebListFilterQuery } from "../components/interfaces/filter.interface";

const defaultQuery: CelebListFilterQuery = {
  page: 0,
  take: 0,
  minEstimatedPrice: 0,
  maxEstimatedPrice: 10000,
  country: "",
  minInstagramFollowers: 0,
  maxInstagramFollowers: 1000000,
  minSearchVolume: 0,
  maxSearchVolume: 1000000,
  isLike: null,
  gender: [],
  type: [],
  order__by: "follower",
};

export const celebFilterAtom = atom<CelebListFilterQuery>(defaultQuery);
