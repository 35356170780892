import React from "react";
import styled from "styled-components";
import StyledButton from "../../button/StyledButton";
import { StepDescription } from "../../common/FormContainer";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { signupAtom } from "../../../atoms/auth";
import { userType } from "../../interfaces/auth.interfaces";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;

const AgencyButton = styled.div`
  display: flex;
  align-items: center;
  width: 42rem;
  height: 10rem;
  padding: 2rem 0;
  border-radius: 0.4rem;
  border: 0.2rem solid transparent;
  gap: 1.6rem;
  padding: 2rem;
  cursor: pointer;
  &:hover,
  &.selected {
    border: 0.2rem solid var(--purple-050);
    box-shadow: 0rem 0rem 1rem 0rem #f3e7fe;
    > .description > .title {
      color: var(--purple-500);
    }
  }

  > .description {
    display: flex;
    flex-direction: column;
    > .title {
      color: var(--gray-900);
      ${({ theme }) => theme.font.body1};
      ${({ theme }) => theme.font.bold};
    }
    > .sub-text {
      margin-top: 0.8rem;
      color: var(--gray-600);
      ${({ theme }) => theme.font.body3};
      ${({ theme }) => theme.font.regular};
    }
  }

  > .icon {
    > .celeb-agency {
      width: 4rem;
      height: 4rem;
      background-image: url("/svg/celeb_agency.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    > .ad-agency {
      width: 4rem;
      height: 4rem;
      background-image: url("/svg/ad_agency.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

const AgencyType = () => {
  const [signupFormData, setSignupFormData] = useAtom(signupAtom);
  const navigate = useNavigate();

  if (!signupFormData) return <></>;

  const handleAgencyType = (type: userType) => {
    setSignupFormData({
      ...signupFormData,
      companyType: type,
    });
  };

  return (
    <>
      <StepDescription step={1} length={4}>
        Choose whether you're a Celebrity or an Advertising Agency.
      </StepDescription>
      <ButtonContainer>
        <AgencyButton
          className={
            signupFormData.companyType === "celebrity_agency" ? "selected" : ""
          }
          onClick={() => handleAgencyType("celebrity_agency")}
        >
          <div className="icon">
            <div className="celeb-agency" />
          </div>
          <div className="description">
            <p className="title">Celeb agency</p>
            <p className="sub-text">Celebrity agency, Manager and Aritst</p>
          </div>
        </AgencyButton>
        <AgencyButton
          className={
            signupFormData.companyType === "advertiser" ? "selected" : ""
          }
          onClick={() => handleAgencyType("advertiser")}
        >
          <div className="icon">
            <div className="ad-agency" />
          </div>
          <div className="description">
            <p className="title">Ad agency</p>
            <p className="sub-text">
              Advertiser, Brand, Advertising or Marketing Agency
            </p>
          </div>
        </AgencyButton>
      </ButtonContainer>
      <StyledButton
        label="Continue"
        disabled={!signupFormData.companyType}
        onClick={() => navigate("../agreement")}
      />
    </>
  );
};

export default AgencyType;
