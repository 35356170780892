import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { ContractFormData } from "../interfaces/contract.interface";
import StyledButton from "../button/StyledButton";
import { useNavigate } from "react-router-dom";
import RegisterInput from "../input/RegisterInput";
import MediaType from "./AdvertisingMedia";
import AdvertisingCountry from "./AdvertisingCountry";
import RegisterTextArea from "../input/RegisterTextArea";
import Period from "./Period";
import Currency from "./Currency";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAtom } from "jotai";
import { contractFormAtom } from "../../atoms/contract";

const schema = z.object({
  currencyType: z.string().min(1, "Currency type is required"),
  amount: z.number().min(1, "Amount is required"),
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required"),
  country: z.array(z.string()).min(1, "At least one country must be selected"),
  advertisingMedia: z
    .array(z.string())
    .min(1, "At least one media type must be selected"),
  comment: z.string(),
  additionalCondition: z.string(),
});

const ProjectInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  border-radius: 0.8rem;
  background-color: var(--gray-100);
  padding: 2rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  .bold {
    ${({ theme }) => theme.font.bold};
  }
  .margin-top {
    margin-top: 2rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
`;

const CreateContract = () => {
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [contractForm, setContractForm] = useAtom(contractFormAtom);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<ContractFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      currencyType: contractForm.projectData?.currencyType
        ? contractForm.projectData?.currencyType
        : "USD",
      amount: contractForm.projectData?.budget
        ? contractForm.projectData?.budget
        : 0,
      startDate: contractForm.projectData?.startDate
        ? contractForm.projectData?.startDate
        : today,
      endDate: contractForm.projectData?.endDate
        ? contractForm.projectData?.endDate
        : "",
      country: contractForm.country ? contractForm.country : [],
      advertisingMedia: contractForm.advertisingMedia
        ? contractForm.advertisingMedia
        : [],
      additionalCondition: "",
      comment: "",
    },
    mode: "onBlur",
    criteriaMode: "all",
  });

  const onSubmit = (data: ContractFormData) => {
    setContractForm({
      ...contractForm,
      currencyType: data.currencyType,
      amount: data.amount,
      startDate: data.startDate,
      endDate: data.endDate,
      country: data.country,
      advertisingMedia: data.advertisingMedia,
      additionalCondition: data.additionalCondition,
      comment: data.comment,
    });
    navigate("../review");
  };

  const advertisingMedia = watch("advertisingMedia");
  const country = watch("country");
  const dates = watch(["startDate", "endDate"]);
  const currencyType = watch("currencyType");

  return (
    <>
      <ProjectInformation>
        <div className="bold">{contractForm.projectData?.title}</div>
        <div>{contractForm.projectData?.description}</div>
        <div className="bold margin-top">{contractForm.celebInfo?.name}</div>
        {contractForm.contractType === "STOCK" ? (
          <div>Selected {contractForm.stockCart?.length} photos</div>
        ) : (
          <div>Social Media Seeding</div>
        )}
      </ProjectInformation>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Currency
          register={register}
          name="amount"
          setValue={setValue}
          currencyType={currencyType}
          errorMessage={{
            amount: errors.amount?.message,
            currencyType: errors.currencyType?.message,
          }}
        />
        <Period
          setValue={setValue}
          label="Contract period"
          value={{
            startDate: dates[0],
            endDate: dates[1],
          }}
          errorMessage={{
            startDate: errors.startDate?.message,
            endDate: errors.endDate?.message,
          }}
        />
        <AdvertisingCountry
          value={country}
          setValue={setValue}
          errorMessage={errors.country?.message}
        />
        <MediaType
          value={advertisingMedia}
          setValue={setValue}
          errorMessage={errors.advertisingMedia?.message}
        />
        <RegisterTextArea
          register={register}
          fieldName="additionalCondition"
          label="Additional condition"
          placeholder="Please enter any additional negotiation details regarding the contract."
        />
        <RegisterInput
          register={register}
          label="Comments"
          fieldName="comment"
        />
        <ButtonContainer>
          <StyledButton
            type="button"
            label="Previous"
            color="white"
            size="sm"
            onClick={() => navigate(-1)}
          />
          <StyledButton
            type="submit"
            label="Complete"
            color="black"
            size="sm"
          />
        </ButtonContainer>
      </Form>
    </>
  );
};

export default CreateContract;
