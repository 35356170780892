import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

const Text = styled(Link)`
  display: flex;
  align-items: center;
  color: var(--gray-500);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  &::before {
    content: "";
    margin-right: 1rem;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    background-image: url("/svg/arrow_left.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`;

interface BreadcrumbProps {
  list: {
    label: string;
    url: string;
  }[];
}

const Breadcrumb = ({ list }: BreadcrumbProps) => {
  return (
    <Container>
      {list.map((item) => {
        return (
          <Text to={item.url} key={"breadcrumb_" + item.url}>
            {item.label}
          </Text>
        );
      })}
    </Container>
  );
};

export default Breadcrumb;
