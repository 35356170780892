import React from "react";
import { LayoutWithOutNavbar } from "../components/layout/Layout";
import Login from "../components/auth/Login";

const LoginPage = () => {
  return (
    <LayoutWithOutNavbar>
      <Login />
    </LayoutWithOutNavbar>
  );
};

export default LoginPage;
