import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Logo from "../layout/Logo";
import { useContractHistory } from "../../hooks/useContract";

const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50rem;
  padding: 4rem;
  border: 0.1rem solid var(--gray-300);
  margin: 6rem auto;
  gap: 4rem;
  position: relative;
`;

const Title = styled.div`
  width: 50%;
  margin-right: auto;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};
  padding-bottom: 1.2rem;
  border-bottom: 0.1rem solid var(--gray-900);
`;

const ContractSection = styled.div`
  width: 100%;
  b {
    ${({ theme }) => theme.font.bold};
  }
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
`;

const Flex = styled.div`
  display: flex;
  gap: 0.8rem;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const Agreement = styled.div`
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  color: var(--gray-900);
  white-space: pre-wrap;
  text-align: left;
  margin-right: auto;
`;

const HistoryListContainer = styled.div`
  position: absolute;
  top: -3.2rem;
  right: 100%;
  width: 18rem;
  margin-right: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const HistoryList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  > .title {
    color: var(--gray-600, #7a7a7a);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
  }
`;

const History = styled.li`
  &:hover {
    box-shadow: 0px 0px 10px 0px #f3e7fe;
    border: 0.1rem solid transparent;
  }
  border: 0.1rem solid var(--gray-300);
  padding: 2rem;
  > .created-at {
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.bold};
  }
  > .contract-title {
    margin-left: 0.8rem;
    padding-left: 0.8rem;
    border-left: 0.1rem solid var(--gray-900);
    margin-top: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    > p {
      color: var(--grey-grey-900, #252525);
      ${({ theme }) => theme.font.body2};
      ${({ theme }) => theme.font.regular};
    }
  }
`;

const ContractHistory = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isPending } = useContractHistory(id);

  if (isPending) return <>Loading</>;
  return (
    <HistoryContainer>
      <Logo width={14} height={6} margin="0" />
      <ContractSection>
        <Title>Contractors</Title>
        <FlexColumn>
          <Flex>
            <b>Party A</b>
            <span>aa</span>
          </Flex>
          <Flex>
            <b>Party A</b>
            <span>celebName</span>
          </Flex>
        </FlexColumn>
      </ContractSection>
      <div
        style={{ width: "100%", borderTop: "0.4rem solid var(--gray-900)" }}
      />
      <Agreement>
        This contract defines the terms and conditions{"\n"}
        between the parties, detailing roles, responsibilities,{"\n"}
        payment terms, and duration. It also covers{"\n"}
        confidentiality, dispute resolution, and termination{"\n"}
        clauses. By signing, both parties agree to the stated{"\n"}
        terms and their obligations.
      </Agreement>
      <ContractSection>
        <Title>Contract Details</Title>
        <FlexColumn>
          <b>Project title</b>
          <span>projectData.title</span>
        </FlexColumn>
        <FlexColumn>
          <b>Project description</b>
          <span>projectData.description</span>
        </FlexColumn>
        <Flex>
          <b>Amount</b>
          <span>contractData.amount</span>
        </Flex>
        <Flex>
          <b>Ad posting date</b>
          <span>contractData.startDate-contractData.endDate</span>
        </Flex>
        <Flex>
          <b>Target countries</b>
          <span>N/A</span>
        </Flex>
        <Flex>
          <b>Advertising media</b>
          <span>N/A</span>
        </Flex>
        <FlexColumn>
          <b>Photos to be used</b>
          {/*<XScroll
              imgList={contractData.stockImage}
              imgWidth="8rem"
              imgHeight="8rem"
              gap="0.8rem"
            ></XScroll>*/}
        </FlexColumn>
        {/*{contractData.additionalCondition && (
            <FlexColumn>
              <b>Additional condition</b>
              <span>{contractData.additionalCondition}</span>
            </FlexColumn>
          )}
          {contractData.comment && (
            <FlexColumn>
              <b>Comments</b>
              <span>{contractData.comment}</span>
            </FlexColumn>
          )}*/}
      </ContractSection>
      <HistoryListContainer>
        <HistoryList>
          <div className="title">Recent</div>
          <History>
            <div className="created-at">06:32 PM, Oct, 15</div>
            <div className="contract-title">
              <p>Contract</p>
              <p>Contract</p>
            </div>
          </History>
        </HistoryList>
        <HistoryList>
          <div className="title">History</div>
          <History>
            <div className="created-at">06:32 PM, Oct, 15</div>
            <div className="contract-title">
              <p>Contract</p>
              <p>Contract</p>
            </div>
          </History>
          <History>
            <div className="created-at">06:32 PM, Oct, 15</div>
            <div className="contract-title">
              <p>Contract</p>
              <p>Contract</p>
            </div>
          </History>
          <History>
            <div className="created-at">06:32 PM, Oct, 15</div>
            <div className="contract-title">
              <p>Contract</p>
              <p>Contract</p>
            </div>
          </History>
        </HistoryList>
      </HistoryListContainer>
    </HistoryContainer>
  );
};

export default ContractHistory;
