interface REQUEST {
  status: "REQUEST";
  subStatus: null;
}
interface NEGOTIATION {
  status: "NEGOTIATION";
  subStatus: null;
}
interface STAKEHOLDER {
  status: "STAKEHOLDER";
  subStatus: null;
}
interface AD_WAITING_CELEB_NO_REQUEST {
  status: "STAKEHOLDER";
  subStatus: "ADVERTISER_WAITING_FOR_SIGNATURE/AGENCY_NO_SIGNATURE_REQUESTED";
}
interface AD_RE_REQUEST_CELEB_REQUEST {
  status: "STAKEHOLDER";
  subStatus: "ADVERTISER_RE_REQUEST_CONFIRM/AGENCY_SIGNATURE_REQUESTED";
}
interface CONTENT_REVIEW {
  status: "CONTENT_REVIEW";
  subStatus: null;
}
interface UPLAOD_MODIFY {
  status: "CONTENT_REVIEW";
  subStatus: "UPLOAD_SIDE_MODIFY_OR_UPLOAD/CONTENT_SIDE_WAITING_FOR_MODIFY";
}
interface WATING_REVIEW {
  status: "CONTENT_REVIEW";
  subStatus: "UPLOAD_SIDE_WAITING_FOR_REVIEW/CONTENT_SIDE_REVIEW_REQUIRED";
}
interface READY_FOR {
  status: "READY_FOR";
  subStatus: null;
}
interface CANCEL {
  status: "CANCEL";
  subStatus: null;
}

export type ContractStatusType =
  | "REQUEST"
  | "NEGOTIATION"
  | "STAKEHOLDER"
  | "CONTENT_REVIEW"
  | "READY_FOR"
  | "CANCEL"
  | null;

export type ContractSubStatusType =
  | "ADVERTISER_WAITING_FOR_SIGNATURE/AGENCY_NO_SIGNATURE_REQUESTED"
  | "ADVERTISER_RE_REQUEST_CONFIRM/AGENCY_SIGNATURE_REQUESTED"
  | "UPLOAD_SIDE_MODIFY_OR_UPLOAD/CONTENT_SIDE_WAITING_FOR_MODIFY"
  | "UPLOAD_SIDE_WAITING_FOR_REVIEW/CONTENT_SIDE_REVIEW_REQUIRED"
  | null;

export type ContractStatusConfig =
  | REQUEST
  | NEGOTIATION
  | STAKEHOLDER
  | AD_WAITING_CELEB_NO_REQUEST
  | AD_RE_REQUEST_CELEB_REQUEST
  | CONTENT_REVIEW
  | UPLAOD_MODIFY
  | WATING_REVIEW
  | READY_FOR
  | CANCEL;

export const createContractStatus = (config: ContractStatusConfig) => config;

export const CONTRACT_STATUS_PRESETS = {
  REQUEST: createContractStatus({
    status: "REQUEST",
    subStatus: null,
  }),

  NEGOTIATION: createContractStatus({
    status: "NEGOTIATION",
    subStatus: null,
  }),

  STAKEHOLDER: createContractStatus({
    status: "STAKEHOLDER",
    subStatus: null,
  }),

  AD_WAITING_CELEB_NO_REQUEST: createContractStatus({
    status: "STAKEHOLDER",
    subStatus: "ADVERTISER_WAITING_FOR_SIGNATURE/AGENCY_NO_SIGNATURE_REQUESTED",
  }),

  AD_RE_REQUEST_CELEB_REQUEST: createContractStatus({
    status: "STAKEHOLDER",
    subStatus: "ADVERTISER_RE_REQUEST_CONFIRM/AGENCY_SIGNATURE_REQUESTED",
  }),

  CONTENT_REVIEW: createContractStatus({
    status: "CONTENT_REVIEW",
    subStatus: null,
  }),

  UPLAOD_MODIFY: createContractStatus({
    status: "CONTENT_REVIEW",
    subStatus: "UPLOAD_SIDE_MODIFY_OR_UPLOAD/CONTENT_SIDE_WAITING_FOR_MODIFY",
  }),

  WATING_REVIEW: createContractStatus({
    status: "CONTENT_REVIEW",
    subStatus: "UPLOAD_SIDE_WAITING_FOR_REVIEW/CONTENT_SIDE_REVIEW_REQUIRED",
  }),

  READY_FOR: createContractStatus({
    status: "READY_FOR",
    subStatus: null,
  }),
  CANCEL: createContractStatus({
    status: "CANCEL",
    subStatus: null,
  }),
} as const;

export const statusList = [
  {
    value: "REQUEST",
    label: "Contract request",
    statusLabel: "Request",
  },
  {
    value: "NEGOTIATION",
    label: "In negotiation",
    statusLabel: "Negotiation",
  },
  {
    value: "STAKEHOLDER",
    label: "Stakeholder signatures",
    statusLabel: "Stakeholder",
  },
  {
    value: "CONTENT_REVIEW",
    label: "Content review",
    statusLabel: "Review",
  },
  {
    value: "READY_FOR",
    label: "Ready for advertisement",
    statusLabel: "Ready",
  },
  {
    value: "CANCEL",
    label: "Cancel",
    statusLabel: "Cancel",
  },
];
