import React from "react";
import styled from "styled-components";

const CheckboxContainer = styled.div`
  input[type="checkbox"] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    cursor: pointer;
  }

  .custom-checkbox {
    width: 1.6rem;
    height: 1.6rem;
    border: 0.15rem solid var(--purple-300);
    border-radius: 0.2rem;
    position: relative;
  }

  .custom-checkbox::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--white);
  }

  input[type="checkbox"]:checked + .custom-checkbox {
    border: 0.15rem solid var(--purple-500);
  }

  input[type="checkbox"]:checked + .custom-checkbox::after {
    content: " ";
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--purple-500);
    background-image: url("/svg/check.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .text-label {
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    color: var(--gray-900);
  }
`;

interface TypeCheckboxProps {
  label: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
}

const StyledCheckbox = ({
  label,
  checked,
  onChange,
  name,
  disabled,
}: TypeCheckboxProps) => {
  return (
    <CheckboxContainer>
      <label htmlFor={name}>
        <input
          id={name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <span className="custom-checkbox" />
        {label && <span className="text-label">{label}</span>}
      </label>
    </CheckboxContainer>
  );
};

export default StyledCheckbox;
