import React from "react";
import styled from "styled-components";
import TimelineIcon from "@mui/icons-material/Timeline";
import InstagramIcon from "@mui/icons-material/Instagram";
import PublicIcon from "@mui/icons-material/Public";
import { CelebListData } from "../interfaces/celeb.interfaces";
import { formatNumber } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import ImageContainer from "../common/ImageContainer";
import FavoriteButton from "../button/FavoriteButton";

const CelebCardWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 1.6rem;
  width: 58rem;
  height: 30rem;
  padding: 2rem;
  border-radius: 0.8rem;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(71, 71, 71, 0.1);
  }
`;

const FavoriteWrapper = styled.div`
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
`;

const CelebSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CelebInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  .name {
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};
    color: var(--gray-900);
  }
  .age {
    color: var(--gray-600);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
  .type {
    color: var(--purple-300);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const CelebScoreList = styled.div`
  margin-top: 3.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  ${({ theme }) => theme.font.body1};
  color: var(--gray-900);
`;

const CelebScore = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  .name {
    ${({ theme }) => theme.font.bold};
  }
  .number {
    ${({ theme }) => theme.font.regular};
  }
`;

interface CelebCardProps {
  celebData: CelebListData;
}

const CelebCard = ({ celebData }: CelebCardProps) => {
  const navigate = useNavigate();
  return (
    <CelebCardWrapper
      onClick={() => {
        navigate(`/celebs/${celebData.id}`);
      }}
    >
      <ImageContainer width={26} height={26} borderRadius={0.4}>
        <img src={celebData.celebMainImg} alt={celebData.name + "thumbnail"} />
        <FavoriteWrapper>
          <FavoriteButton
            id={celebData.id}
            size={32}
            status={celebData.isLike}
            color={{ true: "var(--crimson-600)", false: "var(--gray-050)" }}
          />
        </FavoriteWrapper>
      </ImageContainer>
      <CelebSummary>
        <CelebInfo>
          <p className="name">{celebData.name}</p>
          <p className="age">
            {celebData.birthday}, {celebData.gender}
          </p>
          <p className="type">{celebData.types.join(", ")}</p>
        </CelebInfo>
        <CelebScoreList>
          <CelebScore>
            <InstagramIcon />
            <p className="name">Followers</p>
            <p className="score">
              {celebData.instagramFollowersCnt
                ? formatNumber(celebData.instagramFollowersCnt)
                : "N/A"}
            </p>
          </CelebScore>
          <CelebScore>
            <TimelineIcon />
            <p className="name">Search</p>
            <p className="score">
              {celebData.searchVolume
                ? formatNumber(celebData.searchVolume)
                : "N/A"}
            </p>
          </CelebScore>
          <CelebScore>
            <PublicIcon />
            <p className="name">Popular Countries</p>
          </CelebScore>
          <CelebScore>
            <p className="score" style={{ marginLeft: "2.4rem" }}>
              {celebData.countryName.length
                ? celebData.countryName.join(", ")
                : "N/A"}
            </p>
          </CelebScore>
        </CelebScoreList>
      </CelebSummary>
    </CelebCardWrapper>
  );
};

export default CelebCard;
