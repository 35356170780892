import React, { ReactNode } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const ContentBoxTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  > .title {
    color: var(--gray-900);
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
  }
  > .info {
    color: var(--gray-600);
    text-align: right;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.bold};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 4rem;
  width: 100%;
  border: 0.1rem solid var(--gray-300);
  padding: 6rem 4rem;
  background-color: var(--white);
`;

interface ContentBoxTitleProps {
  title?: string;
  info?: string;
}

const ContentBoxTitle = ({ title, info }: ContentBoxTitleProps) => {
  return (
    <ContentBoxTitleContainer>
      <div className="title">{title}</div>
      <div className="info">{info}</div>
    </ContentBoxTitleContainer>
  );
};

interface ContentBoxProps {
  title?: string;
  info?: string;
  children?: ReactNode;
}
export const ContentBox = ({ title, info, children }: ContentBoxProps) => {
  return (
    <Container>
      {(title || info) && <ContentBoxTitle title={title} info={info} />}
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};
