import React, { useState } from "react";
import styled, { css } from "styled-components";
import { RegisterOptions, UseFormRegister } from "react-hook-form";
import { sizeStyle } from "../common/SizeStyle";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import HelpMessage from "../icon/HelpMessage";

const colorStyle = {
  disabled: css`
    border: 0.1rem solid var(--gray-300);
    border-radius: 0.4rem;
    background-color: var(--white);
    color: var(--gray-900);
  `,
  gray: css`
    border: 0.1rem solid var(--gray-300);
    border-radius: 0.4rem;
    background-color: var(--white);
    color: var(--gray-900);
  `,
  error: css`
    border: 0.1rem solid var(--crimson-600);
    border-radius: 0.4rem;
    background-color: var(--white);
    color: var(--gray-900);
  `,
  default: css`
    border: 0.1rem solid var(--gray-300);
    border-radius: 0.4rem;
    background-color: var(--white);
    color: var(--gray-900);
  `,
};

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

interface InputProps {
  $inputSize: "sm" | "md" | "lg" | "default";
  $color: "gray" | "default";
  $margin?: string;
  $marginTop?: string;
  $marginRight?: string;
  $marginBottom?: string;
  $marginLeft?: string;
  $errorStatus?: boolean;
}

const Input = styled.input<InputProps>`
  font-family: "Inter";
  ${({ $inputSize }) => sizeStyle[$inputSize]};
  ${({ $margin }) => ($margin ? `margin: ${$margin}` : "")};
  ${({ $color }) => colorStyle[$color]};
  ${({ $errorStatus }) => ($errorStatus ? colorStyle.error : "")};
  ${({ $marginTop }) => ($marginTop ? `margin-top: ${$marginTop}` : "")};
  ${({ $marginRight }) =>
    $marginRight ? `margin-right: ${$marginRight}` : ""};
  ${({ $marginBottom }) =>
    $marginBottom ? `margin-bottom: ${$marginBottom}` : ""};
  ${({ $marginLeft }) => ($marginLeft ? `margin-left: ${$marginLeft}` : "")};
  &:disabled {
    ${colorStyle.disabled}
  }
`;

export const LabelGroup = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
`;

export const Label = styled.label<{ $required?: boolean }>`
  color: var(--gray-600);
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.bold};

  &::after {
    content: ${({ $required }) => ($required ? "'*'" : "''")};
    color: var(--crimson-600);
    margin-left: 0.4rem;
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

export const ErrorMessage = styled.p`
  color: var(--crimson-600);
  text-align: right;
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  text-overflow: ellipsis;
`;

const IconWrapper = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 2rem;
  color: var(--gray-600);
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

interface ContractInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputSize?: "sm" | "md" | "lg" | "default";
  color?: "gray" | "default";
  fieldName: string;
  register: UseFormRegister<any>;
  registerOptions?: RegisterOptions<any>;
  label?: string;
  errorMessage?: string;
  margin?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
  required?: boolean;
}

const PasswordInput = ({
                         inputSize = "default",
                         color = "default",
                         fieldName,
                         register,
                         registerOptions = {},
                         label,
                         errorMessage,
                         margin,
                         marginTop,
                         marginRight,
                         marginBottom,
                         marginLeft,
                         required = false,
                         ...options
                       }: ContractInputProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const visibleIcon = (
    <IconWrapper
      type="button"
      onClick={() => setIsVisible(!isVisible)}
      tabIndex={-1}
    >
      {isVisible ? (
        <VisibilityOffOutlinedIcon fontSize="large" />
      ) : (
        <VisibilityOutlinedIcon fontSize="large" />
      )}
    </IconWrapper>
  );

  return (
    <InputGroup>
      {label && (
        <LabelGroup>
          <LabelContainer>
            <Label $required={required}>{label}</Label>
            <HelpMessage>
              Password must be 8+ characters,
              include letters, numbers,
              and at least one special character.
            </HelpMessage>
          </LabelContainer>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </LabelGroup>
      )}
      <div style={{ position: "relative" }}>
        <Input
          $inputSize={inputSize}
          $color={color}
          $margin={margin}
          $marginTop={marginTop}
          $marginRight={marginRight}
          $marginBottom={marginBottom}
          $marginLeft={marginLeft}
          $errorStatus={!!errorMessage}
          type={isVisible ? "text" : "password"}
          {...register(fieldName)}
          {...options}
        />
        {visibleIcon}
      </div>
    </InputGroup>
  );
};

// Confirm Password
export const ConfirmPasswordInput = ({
  inputSize = "default",
  color = "default",
  fieldName,
  register,
  registerOptions = {},
  label,
  errorMessage,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  required = false,
  ...options
}: ContractInputProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const visibleIcon = (
    <IconWrapper
      type="button"
      onClick={() => setIsVisible(!isVisible)}
      tabIndex={-1}
    >
      {isVisible ? (
        <VisibilityOffOutlinedIcon fontSize="large" />
      ) : (
        <VisibilityOutlinedIcon fontSize="large" />
      )}
    </IconWrapper>
  );

  return (
    <InputGroup>
      {label && (
        <LabelGroup>
          <Label $required={required}>{label}</Label>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        </LabelGroup>
      )}
      <div style={{ position: "relative" }}>
        <Input
          $inputSize={inputSize}
          $color={color}
          $margin={margin}
          $marginTop={marginTop}
          $marginRight={marginRight}
          $marginBottom={marginBottom}
          $marginLeft={marginLeft}
          $errorStatus={!!errorMessage}
          type={isVisible ? "text" : "password"}
          {...register(fieldName)}
          {...options}
        />
        {visibleIcon}
      </div>
    </InputGroup>
  );
};

export default PasswordInput;
