import React from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useStockCartList from "../../../hooks/useStockCartList";
import styled from "styled-components";
import StyledButton from "../../button/StyledButton";
import CheckButton from "../../button/CheckButton";
import { useAtom } from "jotai";
import { contractFormAtom } from "../../../atoms/contract";
import { FormContainer, StepDescription } from "../../common/FormContainer";

const TypeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Type = styled.div`
  width: 42rem;
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 2.6rem 4rem;
  border-radius: 0.4rem;
  border: 0.2rem solid transparent;
  background: var(--white);
  cursor: pointer;
  .text {
    margin-left: 2rem;
  }

  &:hover,
  &.selected {
    border: 0.2rem solid var(--purple-050);
    box-shadow: 0px 0px 10px 0px #f3e7fe;
  }
`;

const CartWrapper = styled.div<{ $length: number | undefined }>`
  font-size: 3.4rem;
  margin-left: auto;
  position: relative;
  .cart-icon {
    position: relative;
    background-image: url("/svg/cart.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 4rem;
    height: 4rem;
    &::after {
      display: block;
      position: absolute;
      top: -0.4rem;
      right: -0.4rem;
      content: "${({ $length }) => ($length ? $length : "")}";
      color: var(--white);
      width: 1.6rem;
      height: 1.6rem;
      text-align: center;
      align-content: center;
      font-size: 1rem;
      background-color: var(--crimson-600);
      border-radius: 50%;
    }
  }
`;

const ContactUs = styled.div`
  white-space: pre-wrap;
  text-align: center;
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  > span > b {
    ${({ theme }) => theme.font.bold};
  }
`;

const SelectType = () => {
  const { celebId } = useParams();
  const [contractForm, setContractForm] = useAtom(contractFormAtom);
  const { stockList, isEmpty } = useStockCartList(celebId);
  const navigate = useNavigate();

  if (!celebId) return <Navigate to="../" />;

  const handleTypeSelect = (
    type: "STOCK" | "SEEDING" | "STOCK_SEEDING" | ""
  ) => {
    setContractForm({
      ...contractForm,
      contractType: type === contractForm.contractType ? "" : type,
    });
  };

  const onClickNext = () => {
    setContractForm({
      ...contractForm,
      stockCart: stockList,
    });
    navigate("project");
  };

  return (
    <>
      <FormContainer>
        <StepDescription step={1} length={5}>
          Please select the contract type.
        </StepDescription>
        <TypeList>
          {!isEmpty() && (
            <Type
              onClick={() => handleTypeSelect("STOCK")}
              className={
                contractForm.contractType === "STOCK" ? "selected" : ""
              }
            >
              <CheckButton
                status={contractForm.contractType === "STOCK"}
                setStatus={() => handleTypeSelect("STOCK")}
                size={40}
              />
              <p className="text">Use selected photos</p>
              <CartWrapper $length={stockList?.length}>
                <div className="cart-icon" />
              </CartWrapper>
            </Type>
          )}

          <Type
            onClick={() => handleTypeSelect("SEEDING")}
            className={
              contractForm.contractType === "SEEDING" ? "selected" : ""
            }
          >
            <CheckButton
              status={contractForm.contractType === "SEEDING"}
              setStatus={() => handleTypeSelect("SEEDING")}
              size={40}
            />
            <p className="text">Social media seeding</p>
          </Type>
        </TypeList>

        <StyledButton
          onClick={() => onClickNext()}
          label="Next"
          size="lg"
          color="black"
          style={{ margin: "4rem auto 0" }}
          disabled={!contractForm.contractType}
        />
      </FormContainer>
      <FormContainer>
        <ContactUs>
          <span>
            Should you have any additional requests,{"\n"}
            such as <b>extra shoots, event invitations,</b>
            {"\n"}
            <b>or ambassador collaborations,</b>
            {"\n\n"}
            please feel free to let us know.
          </span>
          <StyledButton
            onClick={() => window.open("mailto:support@aligo.ai")}
            // onClick={() => navigate("/contact-us")}
            label="support@aligo.ai"
            size="lg"
            color="white"
            mt={4}
          />
        </ContactUs>
      </FormContainer>
    </>
  );
};
export default SelectType;
