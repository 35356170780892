import React from "react";
import { useAtom } from "jotai";
import { contractFormAtom } from "../../../atoms/contract";
import { Navigate } from "react-router-dom";
import CreateContract from "../../contract/CreateContract";
import { FormContainer, StepDescription } from "../../common/FormContainer";

const ContractForm = () => {
  const [contractForm] = useAtom(contractFormAtom);

  if (!contractForm.projectData) return <Navigate to="../" />;
  return (
    <FormContainer>
      <StepDescription step={3} length={5}>
        Please provide the contract details.
      </StepDescription>
      <CreateContract />
    </FormContainer>
  );
};

export default ContractForm;
