import React, { useEffect } from "react";
import CelebListSidebar from "./CelebListSidebar";
import CelebCardList from "./CelebCardList";
import Filter from "./Filter";
import styled from "styled-components";
import { CelebListFilterQuery } from "../interfaces/filter.interface";
import Layout from "../layout/Layout";
import { useAtom } from "jotai";
import { celebFilterAtom } from "../../atoms/filter";
import { useCelebList } from "../../hooks/useCeleb";

const Wrapper = styled.div`
  display: flex;
  gap: 4rem;
  width: 100%;
`;

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  min-width: 117.6rem;
`;

const CelebList = () => {
  const [query, setQuery] = useAtom<CelebListFilterQuery>(celebFilterAtom);
  const { isPending, data, refetch } = useCelebList(query);

  const queryHandler = (
    name: keyof CelebListFilterQuery,
    value: string | number,
    checked?: boolean
  ) => {
    setQuery((prevQuery) => {
      const targetValue = prevQuery[name];
      if (
        checked !== undefined &&
        typeof value === "string" &&
        Array.isArray(targetValue)
      ) {
        if (targetValue.every((item) => typeof item === "string")) {
          const updatedArray = checked
            ? [...targetValue, value]
            : targetValue.filter((item) => item !== value);

          return {
            ...prevQuery,
            [name]: updatedArray,
          };
        }
      }

      return {
        ...prevQuery,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    refetch();
  }, [query.gender, query.order__by]);

  if (isPending) return <></>;
  if (!data) return <></>;

  return (
    <Layout>
      <Wrapper>
        <CelebListSidebar
          query={query}
          setQuery={queryHandler}
          refetch={refetch}
        />
        <MainSection>
          <Filter query={query} setQuery={queryHandler} />
          <CelebCardList celebList={data.data} />
        </MainSection>
      </Wrapper>
    </Layout>
  );
};

export default CelebList;
