import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Breadcrumb from "../../common/Breadcrumb";
import { useSearchParams } from "react-router-dom";
import { useContract } from "../../../hooks/useContract";
import StatusBar from "../../contract/StatusBar";
import { Flex, FlexColumn } from "../TableFlex";
import ContentUpload from "../ContentUpload";
import FinalAdContent from "../FinalAdContent";
import ImageScroll from "../../common/ImageScroll";
import { useAtomValue } from "jotai";
import { userDataAtom } from "../../../atoms/auth";
import { StepRequestAdvertiser, StepRequestCelebAgency } from "../StepRequest";
import { StepNegotiation } from "../StepNegotiation";
import SignatureForm from "../SignatureForm";
import ContentModify from "../ContentModify";
import ContentConfirm from "../ContentConfirm";
import WaitStatus from "../WaitStatus";

const ContractDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`;

const ContractInfoContainer = styled.div`
  margin-top: 1.6rem;
  border: 0.1rem solid var(--gray-300);
  display: flex;
  flex-direction: column;
  background-color: var(--white);
`;

const CelebName = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.bold};
  color: var(--gray-900);
  .contract-id {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

const ContractInfo = styled.div`
  border-top: 0.1rem solid var(--gray-300);
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ContractDetail = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId") || "";
  const contractId = searchParams.get("contractId") || "";
  const { data, refetch } = useContract(contractId || undefined);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const user = useAtomValue(userDataAtom);
  const userType = user?.companyType;
  const isAdvertiser = userType === "advertiser";
  const isCelebrityAgency = userType === "celebrity_agency";

  useEffect(() => {
    if (data?.data.status === "CONTENT_REVIEW") {
      setIsOpen(false);
    }
  }, [data?.data.status]);

  const renderAdvertiserStatusComponent = () => {
    switch (data?.data.status) {
      case "REQUEST":
        return (
          <StepRequestAdvertiser contractId={contractId} refetch={refetch} />
        );
      case "NEGOTIATION":
        return (
          <StepNegotiation
            contractData={data.data}
            userType={userType}
            refetch={refetch}
          />
        );
      case "STAKEHOLDER":
        return (
          <SignatureForm
            contractId={contractId}
            subStatus={data.data.subStatus}
            userType={userType}
            refetch={refetch}
          />
        );
      case "CONTENT_REVIEW":
        if (data?.data.contractType === "STOCK") {
          if (data?.data.subStatus === null) {
            return <ContentUpload contractData={data.data} refetch={refetch} />;
          } else {
            return (
              <ContentModify
                subStatus={data.data.subStatus}
                contractId={contractId}
                refetch={refetch}
              />
            );
          }
        } else if (data?.data.contractType === "SEEDING") {
          if (data?.data.subStatus === null) {
            return <WaitStatus />;
          } else {
            return (
              <ContentConfirm
                subStatus={data.data.subStatus}
                contractId={contractId}
                refetch={refetch}
              />
            );
          }
        }
        return <></>;
      case "READY_FOR":
        return <FinalAdContent contractId={contractId} />;
      default:
        return null;
    }
  };

  const renderCelebrityAgencyStatusComponent = () => {
    switch (data?.data.status) {
      case "REQUEST":
        return (
          <StepRequestCelebAgency contractId={contractId} refetch={refetch} />
        );
      case "NEGOTIATION":
        return (
          <StepNegotiation
            contractData={data.data}
            userType={userType}
            refetch={refetch}
          />
        );
      case "STAKEHOLDER":
        return (
          <SignatureForm
            contractId={contractId}
            subStatus={data.data.subStatus}
            userType={userType}
            refetch={refetch}
          />
        );
      case "CONTENT_REVIEW":
        if (data?.data.contractType === "STOCK") {
          if (data?.data.subStatus === null) {
            return <WaitStatus />;
          } else {
            return (
              <ContentConfirm
                contractId={contractId}
                subStatus={data.data.subStatus}
                refetch={refetch}
              />
            );
          }
        } else if (data?.data.contractType === "SEEDING") {
          if (data?.data.subStatus === null) {
            return <ContentUpload contractData={data.data} refetch={refetch} />;
          } else {
            return (
              <ContentModify
                subStatus={data.data.subStatus}
                contractId={contractId}
                refetch={refetch}
              />
            );
          }
        }
        return <></>;
      case "READY_FOR":
        return <FinalAdContent contractId={contractId} />;
      default:
        return null;
    }
  };

  if (!data) return <></>;

  return (
    <>
      {isAdvertiser && (
        <Breadcrumb
          list={[
            { label: "Projects", url: "../" },
            {
              label: data.data.projectTitle,
              url: `../detail?projectId=${projectId}`,
            },
          ]}
        />
      )}
      {isCelebrityAgency && (
        <Breadcrumb list={[{ label: "Contracts", url: "../" }]} />
      )}
      <ContractDetailContainer>
        <StatusBar
          currentStatus={data.data.status ? data.data.status : "NEOGTIATION"}
        />
        <ContractInfoContainer>
          <CelebName onClick={() => setIsOpen(!isOpen)}>
            {data.data.celeb?.name}
            <span className="contract-id">
              Contract number : {data.data.id}
            </span>
          </CelebName>
          {isOpen && (
            <ContractInfo>
              <Flex>
                <span className="title">Project name</span>
                <span className="value">{data.data?.projectTitle}</span>
              </Flex>
              <Flex>
                <span className="title">Project description</span>
                <span className="value">{data.data.projectDescription}</span>
              </Flex>
              <Flex>
                <span className="title">Amount</span>
                <span className="value">{data.data?.amount}</span>
              </Flex>
              <Flex>
                <span className="title">Ad posting date</span>
                <span className="value">
                  {data.data?.adStartDate} - {data.data?.adEndDate}
                </span>
              </Flex>
              <Flex>
                <span className="title">Target countries</span>
                <span className="value">
                  {data.data?.countrys
                    .map((country) => country.countryName)
                    .join(", ")}
                </span>
              </Flex>
              <Flex>
                <span className="title">Advertising medias</span>
                <span className="value">
                  {data.data?.advertisers
                    .map((advertisers) => advertisers.companyName)
                    .join(", ")}
                </span>
              </Flex>
              <Flex>
                <span className="title">Additional condition</span>
                <span className="value">{data.data?.additionalCondition}</span>
              </Flex>

              <FlexColumn>
                <span className="title">Photos to be used</span>
                <span className="value">
                  <ImageScroll
                    imgList={data.data.stockImages.map((data) => data.url)}
                    maxWidth={104.4}
                    width={16}
                    height={16}
                    gap={1.6}
                  />
                </span>
              </FlexColumn>
            </ContractInfo>
          )}
        </ContractInfoContainer>
        {isAdvertiser && renderAdvertiserStatusComponent()}
        {isCelebrityAgency && renderCelebrityAgencyStatusComponent()}
      </ContractDetailContainer>
    </>
  );
};

export default ContractDetail;
