import React, { ReactNode } from "react";
import styled from "styled-components";

interface SectionStyleProps {
  $bgColor?: string;
  $bgUrl?: string;
  $margin?: string;
  $ml?: number;
  $mt?: number;
  $mr?: number;
  $mb?: number;
  $padding?: string;
  $pl?: number;
  $pt?: number;
  $pr?: number;
  $pb?: number;
  $border?: string;
  $bl?: string;
  $bt?: string;
  $br?: string;
  $bb?: string;
  $unit?: string;
  $children?: ReactNode;
  [key: string]: any;
}
const SectionStyle = styled.section<SectionStyleProps>`
  ${({ $bgColor }) => ($bgColor ? `background-color: ${$bgColor}` : "")};
  ${({ $bgUrl }) => ($bgUrl ? `background-image: url(${$bgUrl})` : "")};
  ${({ $bgUrl }) => ($bgUrl ? "background-repeat: no-repeat" : "")};
  ${({ $bgUrl }) => ($bgUrl ? "background-position: center top" : "")};
  ${({ $bgUrl }) => ($bgUrl ? "background-size: cover" : "")};
  ${({ $margin }) => ($margin ? `margin: ${$margin}` : "")};
  ${({ $ml, $unit }) => ($ml ? `margin-left: ${$ml}` + $unit : "")};
  ${({ $mt, $unit }) => ($mt ? `margin-top: ${$mt}` + $unit : "")};
  ${({ $mr, $unit }) => ($mr ? `margin-right: ${$mr}` + $unit : "")};
  ${({ $mb, $unit }) => ($mb ? `margin-bottom: ${$mb}` + $unit : "")};
  ${({ $padding }) => ($padding ? `padding: ${$padding}` : "")};
  ${({ $pl, $unit }) => ($pl ? `padding-left: ${$pl}` + $unit : "")};
  ${({ $pt, $unit }) => ($pt ? `padding-top: ${$pt}` + $unit : "")};
  ${({ $pr, $unit }) => ($pr ? `padding-right: ${$pr}` + $unit : "")};
  ${({ $pb, $unit }) => ($pb ? `padding-bottom: ${$pb}` + $unit : "")};
  ${({ $border }) => ($border ? `border: ${$border}` : "")};
  ${({ $bl }) => ($bl ? `border-left: ${$bl}` : "")};
  ${({ $bt }) => ($bt ? `border-top: ${$bt}` : "")};
  ${({ $br }) => ($br ? `border-right: ${$br}` : "")};
  ${({ $bb }) => ($bb ? `border-bottom: ${$bb}` : "")};
`;

const Contents = styled.div`
  width: 100%;
  max-width: 160rem;
  margin: 0 auto;
  /*${({ theme }) => theme.media.sm} {
    width: 100%;
  }
  ${({ theme }) => theme.media.md} {
    width: 100%;
  }
  ${({ theme }) => theme.media.lg} {
    width: 100%;
  }
  ${({ theme }) => theme.media.xl} {
    width: 144rem;
  }*/
`;
interface SectionProps {
  bgColor?: string;
  bgUrl?: string;
  margin?: string;
  ml?: number;
  mt?: number;
  mr?: number;
  mb?: number;
  padding?: string;
  pl?: number;
  pt?: number;
  pr?: number;
  pb?: number;
  border?: string;
  bl?: string;
  bt?: string;
  br?: string;
  bb?: string;
  unit?: string;
  children?: ReactNode;
  [key: string]: any;
}

const Section = ({
  bgColor,
  bgUrl,
  margin,
  ml,
  mt,
  mr,
  mb,
  padding,
  pl,
  pt,
  pr,
  pb,
  border,
  bl,
  bt,
  br,
  bb,
  unit = "rem",
  children,
  ...options
}: SectionProps) => {
  return (
    <SectionStyle
      $bgColor={bgColor}
      $bgUrl={bgUrl}
      $margin={margin}
      $ml={ml}
      $mt={mt}
      $mr={mr}
      $mb={mb}
      $padding={padding}
      $pl={pl}
      $pt={pt}
      $pr={pr}
      $pb={pb}
      $border={border}
      $bl={bl}
      $bt={bt}
      $br={br}
      $bb={bb}
      $unit={unit}
      {...options}
    >
      <Contents>{children}</Contents>
    </SectionStyle>
  );
};

export default Section;
