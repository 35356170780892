import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { putCancelContract, putContractStatus } from "../api/contract";
import {
  CancelContractResponse,
  ContractStatusSuccessResponse,
} from "../components/interfaces/contract.interface";
import { ContractStatusConfig } from "../configs/contractStatus";

export interface UseMutationOptions {
  onSuccess?: () => void;
  onError?: (error: AxiosError) => void;
}

export const useContractStatus = (
  contractId: string,
  options?: UseMutationOptions
) => {
  const mutation = useMutation<
    ContractStatusSuccessResponse,
    AxiosError,
    ContractStatusConfig
  >({
    mutationFn: (data: ContractStatusConfig) =>
      putContractStatus(contractId, data),
    onSuccess: () => {
      options?.onSuccess?.();
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const updateStatus = (config: ContractStatusConfig) => {
    mutation.mutate(config);
  };

  return {
    updateStatus,
    isLoading: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};

export const useCancelContract = (
  contractId: string,
  options?: UseMutationOptions
) => {
  const mutation = useMutation<CancelContractResponse, AxiosError, string>({
    mutationFn: (cancelReason) => putCancelContract(contractId, cancelReason),
    onSuccess: () => {
      options?.onSuccess?.();
    },
    onError: (error) => {
      options?.onError?.(error);
    },
  });

  const cancelContract = (cancelReason: string) => {
    mutation.mutate(cancelReason);
  };

  return {
    cancelContract,
    isLoading: mutation.isPending,
    error: mutation.error,
    isError: mutation.isError,
    isSuccess: mutation.isSuccess,
  };
};
