import React from "react";
import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckButton from "../../button/CheckButton";
import StyledButton from "../../button/StyledButton";
import { Navigate, useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { signupAtom } from "../../../atoms/auth";
import { StepDescription } from "../../common/FormContainer";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -3.6rem;
  /* margin-top: 0.4rem; */
`;

const CheckText = styled.li`
  width: 100%;
  display: flex;
  cursor: pointer;
  padding: 0.8rem 0;
  gap: 0.8rem;
  align-items: center;
  > .MuiSvgIcon-root {
    margin-left: auto;
    font-size: 2.4rem;
    color: var(--gray-500);
  }
`;

const Description = styled.span`
  color: var(--gray-600);
  white-space: pre-wrap;
  ${({ theme }) => theme.font.body3};
  ${({ theme }) => theme.font.regular};
  margin-left: 3.4rem;
`;

const CheckList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Border = styled.div`
  border-top: 0.1rem solid var(--gray-300);
  width: 95%;
  margin: 2rem auto;
`;

interface CheckProps {
  value: boolean;
  setValue: () => void;
}

const Check = ({ value, setValue }: CheckProps) => {
  return (
    <CheckButton
      size={24}
      status={value}
      setStatus={() => setValue()}
      color={{
        true: "var(--purple-300)",
        false: "var(--gray-500)",
      }}
    />
  );
};

const documentList = [
  {
    key: "agree",
    title: "Terms of Service 01",
    mandatory: true,
  },
  {
    key: "marketAgree",
    title: "Terms of Service 02",
    mandatory: false,
  },
];

export interface AgreeCheck {
  agree: boolean;
  marketAgree: boolean;
}

const Agreement = () => {
  const [signupForm, setSignupForm] = useAtom(signupAtom);
  const navigate = useNavigate();
  const isAllChecked = signupForm.agree && signupForm.marketAgree;

  const handleCheck = (key: keyof AgreeCheck) => {
    setSignupForm({
      ...signupForm,
      [key]: !signupForm[key],
    });
  };

  const handleCheckAll = () => {
    const newStatus = !isAllChecked;
    setSignupForm({
      ...signupForm,
      agree: newStatus,
      marketAgree: newStatus,
    });
  };

  if (!signupForm.companyType) {
    return <Navigate to="../" />;
  }

  return (
    <>
      <StepDescription step={2} length={4} />
      <Container>
        <CheckText>
          <Check value={isAllChecked} setValue={handleCheckAll} />
          <span onClick={handleCheckAll}>Agree to all.</span>
        </CheckText>
        <Description>
          Please note that agreeing to all includes both mandatory and{"\n"}
          optional information.{"\n"}You may choose to agree to each item
          individually.{"\n"}Even if you refuse to consent to optional items,
          {"\n"}you can still use the service.
        </Description>
        <Border />
        <CheckList>
          {documentList.map((document) => (
            <CheckText key={document.key}>
              <Check
                value={signupForm[document.key as keyof AgreeCheck]}
                setValue={() => handleCheck(document.key as keyof AgreeCheck)}
              />
              <span
                onClick={() => handleCheck(document.key as keyof AgreeCheck)}
              >
                {document.mandatory ? "[Mandatory] " : "[Optional]  "}
                {document.title}
              </span>
              <KeyboardArrowRightIcon />
            </CheckText>
          ))}
        </CheckList>
        <StyledButton
          label="Continue"
          onClick={() => navigate("../detail")}
          disabled={!signupForm.agree}
          mt={4}
        />
      </Container>
    </>
  );
};

export default Agreement;
