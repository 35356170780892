import React from "react";
import styled from "styled-components";

const NewsCardContainer = styled.div<{ $bgUrl: string }>`
  width: 71rem;
  height: 42rem;
  display: flex;
  flex-direction: column;
  justify-content: end;
  border-radius: 0.4rem;
  padding: 4rem;
  background-image: url(${({ $bgUrl }) => $bgUrl});
  background-repeat: no-repeat;
  background-position: top;
  /*background-position: center;*/
  background-size: cover;
  gap: 1.2rem;
  color: var(--white);
  > .name {
    ${({ theme }) => theme.font.h1};
    ${({ theme }) => theme.font.bold};
  }
  > .description {
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.regular};
    white-space: pre-wrap;
  }
  > .date {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
  }
`;

interface NewsCardData {
  name: string;
  description: string;
  date: string;
  backgroundUrl: string;
}

const NewsCard = ({ news }: { news: NewsCardData }) => {
  return (
    <NewsCardContainer $bgUrl={news.backgroundUrl}>
      {/*<div className="name">{news.name}</div>*/}
      {/*<div className="description">{news.description}</div>*/}
      {/*<div className="date">{news.description}</div>*/}
    </NewsCardContainer>
  );
};

const NewsCardListContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  justify-content: center;
`;

const cardImageList = [
  {
    name: "Kim Eui-Sung",
    description: "'SNL Korea' - 'I am Kim Eui-sung!'",
    backgroundUrl: "/image/main/news_01.png",
    date: "18 Jul 2024",
  },
  {
    name: "Kim Joo-ryeong",
    description: "Kim Joo-ryung Cast as Lead in Mystery Thriller 'Sisterhood' ",
    backgroundUrl: "/image/main/news_02.png",
    date: "18 Jul 2024",
  },
];

const NewsCardList = () => {
  return (
    <NewsCardListContainer>
      <NewsCard news={cardImageList[0]}></NewsCard>
      <NewsCard news={cardImageList[1]}></NewsCard>
    </NewsCardListContainer>
  );
};

export default NewsCardList;
