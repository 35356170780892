import React, { useState } from "react";
import { ModalFrame, useModal } from "../components/common/ModalUtil";
import { MODAL_TYPES } from "../atoms/modal";
import styled from "styled-components";
import Textarea from "../components/input/Textarea";
import StyledButton from "../components/button/StyledButton";
import { useCancelContract } from "../hooks/useContractStatus";

const ModalDescription = styled.div`
  color: var(--gray-900);
  ${({ theme }) => theme.font.title1};
  ${({ theme }) => theme.font.bold};
  white-space: pre-wrap;
`;

const TextareaDescriptipn = styled.div`
  color: var(--gray-600);
  margin-top: 0.8rem;
  padding: 0.8rem;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.font.body3};
  ${({ theme }) => theme.font.regular};
`;

const CancelTextarea = styled.textarea`
  width: 42rem;
  height: 16rem;
  padding: 2rem;
  overflow-y: hidden;
  border-radius: 0.4rem;
  ${({ theme }) => theme.font.inter};
  ${({ theme }) => theme.font.body1};
  ${({ theme }) => theme.font.regular};
  border: 0.1rem solid var(--gray-300);
  background: var(--white);
  resize: none;
  outline: none;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const CancelContract = () => {
  const [text, setText] = useState("");
  const { hideModal, modalState } = useModal(MODAL_TYPES.CANCEL_CONTRACT);
  const { cancelContract } = useCancelContract(modalState?.data?.contractId, {
    onSuccess: () => {
      modalState?.data?.refetch?.();
      setText("");
      hideModal();
    },
    onError: (error) => {
      console.error("계약 취소 중 오류 발생:", error);
    },
  });

  const onSubmit = () => {
    if (text.length < 30) {
      return;
    }
    cancelContract(text);
  };

  return (
    <ModalFrame
      width={50}
      padding={4}
      unit="rem"
      modalType={MODAL_TYPES.CANCEL_CONTRACT}
    >
      <ModalDescription>
        Please state the reason{"\n"}
        for canceling the contract.
      </ModalDescription>
      <TextareaDescriptipn>
        <span>Please write at least 30 characters.</span>
        <span>{text.replace(/\s+/g, "").length}/30</span>
      </TextareaDescriptipn>
      <CancelTextarea value={text} onChange={(e) => setText(e.target.value)} />
      <ButtonContainer>
        <StyledButton
          size="sm"
          type="button"
          label="Cancel"
          color="white"
          onClick={() => hideModal()}
        />
        <StyledButton
          size="sm"
          type="button"
          label="Confirm"
          color="black"
          disabled={text.replace(/\s+/g, "").length < 30}
          onClick={() => onSubmit()}
        />
      </ButtonContainer>
    </ModalFrame>
  );
};

export default CancelContract;
