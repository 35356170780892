import React from "react";
import CancelContract from "./CancelContract";

const Modals = () => {
  return (
    <>
      <CancelContract />
    </>
  );
};

export default Modals;
