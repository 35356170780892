import { CreateProjectFormData } from "../components/interfaces/project.interface";
import { privateClient } from "./axiosInstance";

export const createProject = async (data: CreateProjectFormData) => {
  const response = await privateClient.post("/api/v1/project", data);

  return response.data;
};

export const getProjectList = async () => {
  const response = await privateClient.get("/api/v1/project");

  return response.data;
};

export const getProject = async (id: string | undefined) => {
  const response = await privateClient.get("/api/v1/project/" + id);

  return response.data;
};
