import { useMutation } from "@tanstack/react-query";
import {
  DataErrorResponse,
  InputValidationErrorResponse,
  LoginFormData,
  LoginSuccessResponse,
} from "../components/interfaces/auth.interfaces";
import { postLogin } from "../api/auth";
import { AxiosError } from "axios";
import { updateTokens, userDataAtom } from "../atoms/auth";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";

export const useLogin = () => {
  const [, setUserData] = useAtom(userDataAtom);
  const navigate = useNavigate();

  const loginMutation = useMutation<
    LoginSuccessResponse,
    AxiosError<DataErrorResponse | InputValidationErrorResponse>,
    LoginFormData
  >({
    mutationFn: postLogin,
    onSuccess: (data) => {
      updateTokens(data.data.accessToken, data.data.refreshToken);
      setUserData(data.data.user);
      if (data.data.user.companyType === "advertiser") {
        navigate("/celebs");
      } else if (data.data.user.companyType === "celebrity_agency") {
        navigate("/dashboard");
      }
    },
    onError: (error) => {},
  });

  const login = (data: LoginFormData) => {
    loginMutation.mutate(data);
  };

  return {
    login,
    isPending: loginMutation.isPending,
    error: loginMutation.error,
    isError: loginMutation.isError,
    isSuccess: loginMutation.isSuccess,
  };
};
