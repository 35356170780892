import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface FadeContainerProps {
  $status: boolean;
  $time: number;
}

const FadeContainer = styled.div<FadeContainerProps>`
  & > * {
    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fadeout {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
    animation: ${(props) =>
      props.$status ? `fadein ${props.$time}ms` : `fadeout ${props.$time}ms`};
  }
`;

interface FadeProps {
  children: React.ReactNode;
  status: boolean;
  time: number;
}

const Fade = ({ children, status, time = 100 }: FadeProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(status);

  useEffect(() => {
    if (status === true) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, time - time / 10);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [status, time]);

  return isVisible ? (
    <FadeContainer $status={status} $time={time}>
      {children}
    </FadeContainer>
  ) : (
    <></>
  );
};

export default Fade;
