import React from "react";
import { AudienceAge } from "../interfaces/celeb.interfaces";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import styled from "styled-components";

interface AudienceAgeChartProps {
  data: AudienceAge[];
}

const TooltipContainer = styled.div`
  padding: 0.2rem 0.6rem;
  border-radius: 1.2rem;
  border: 0.1rem solid var(--gray-100);
  color: var(--gray-600);
  background-color: white;
  position: absolute;
  top: -1.5rem;
  left: 4rem;
  white-space: nowrap;
  color: var(--gray-600);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  /* &.recharts-tooltip-wrapper {
    left: 50%;
  } */
`;

const AudienceAgeChart = ({ data }: AudienceAgeChartProps) => {
  const maleData = data.find((d) => d.gender === "male")?.byAgeGroup || [];
  const femaleData = data.find((d) => d.gender === "female")?.byAgeGroup || [];

  const chartData = maleData.map((group, index) => ({
    group: group.group,
    male: group.value,
    female: femaleData[index]?.value || 0,
  }));
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          {label}
          <p className="label">{`${payload[0].dataKey} : ${payload[0].value}%`}</p>
          <p className="label">{`${payload[1].dataKey} : ${payload[1].value}%`}</p>
        </TooltipContainer>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={210}>
      <BarChart width={730} height={250} data={chartData}>
        <XAxis dataKey="group" tick={false} axisLine={false} tickLine={false} />
        {/* <Tooltip /> */}
        <Tooltip content={CustomTooltip} />
        <Bar dataKey="female" fill="#F35F8D" radius={[10, 10, 0, 0]} />
        <Bar dataKey="male" fill="#8BC6FF" radius={[10, 10, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default AudienceAgeChart;
