import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import StyledButton from "../../button/StyledButton";
import { useContractStatus } from "../../../hooks/useContractStatus";
import { ContractSign } from "../../interfaces/contract.interface";
import {
  CONTRACT_STATUS_PRESETS,
  ContractSubStatusType,
} from "../../../configs/contractStatus";
import { useContractSign } from "../../../hooks/useContract";
import {
  useSignFilePostUpload,
  useSignFileUpload,
} from "../../../hooks/useFileUpload";

const Container = styled.div`
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const UploadBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

const UploadBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 6rem);
  grid-template-areas:
    "name signature"
    ". button-container";

  > .name {
    grid-area: name;
    color: var(--gray-900);
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.bold};
    align-content: center;
  }
  > .signature {
    grid-area: signature;
    color: var(--gray-600);
    ${({ theme }) => theme.font.title1};
    ${({ theme }) => theme.font.regular};
    align-content: center;
    text-align: center;
    overflow: hidden;
    > img {
      width: 100%;
      object-fit: contain;
    }
  }
  > .button-container {
    border-top: 0.1rem dashed var(--gray-600);
    grid-area: button-container;
    display: flex;
    justify-content: center;
    align-items: end;
    gap: 1.2rem;
  }
`;

interface UploadSignatureProps {
  signData: ContractSign;
  contractId: string;
  refetch: () => void;
  subStatus: ContractSubStatusType;
}

const SignatureUpload = ({
  signData,
  contractId,
  refetch,
  subStatus,
}: UploadSignatureProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [signatureUrl, setSignatureUrl] = useState<string | null>(null);

  useEffect(() => {
    if (signData.contractSign[0]?.signImageUrl) {
      const firstImage = signData.contractSign[0].signImageUrl;
      setSignatureUrl(firstImage);
    }
  }, [signData]);

  const { updateStatus } = useContractStatus(contractId, {
    onSuccess: () => {
      refetch();
    },
  });

  const { isPending, data } = useContractSign(contractId);

  const { uploadFile, isPending: signFileIsPending } = useSignFileUpload({
    onSuccess: (response) => {
      setSignatureUrl(response.data);
    },
    onError: (error) => {
      console.error("서명 이미지 업로드 실패:", error);
    },
  });

  const { postSign, isPending: signFilePostIsPending } = useSignFilePostUpload({
    onSuccess: (response) => {
      setSignatureUrl(response.data.signImageUrl);
    },
    onError: (error) => {
      console.error("서명 이미지 업로드 실패:", error);
    },
  });

  const onClickRequest = () => {
    updateStatus(CONTRACT_STATUS_PRESETS.AD_RE_REQUEST_CELEB_REQUEST);
    if (signatureUrl) {
      postSign({
        signImageUrl: signatureUrl,
        contractId: contractId,
        celebId: signData?.celeb.id,
        celebAgencyId: signData?.celebAgency.id,
      });
    }
    refetch();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("image", file);
      uploadFile(formData);
      e.target.value = "";
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const disabledStatus = () => {
    if (
      subStatus === "ADVERTISER_RE_REQUEST_CONFIRM/AGENCY_SIGNATURE_REQUESTED"
    ) {
      return true;
    }
    return false;
  };

  if (isPending) return <>Loading</>;

  return (
    <Container>
      <UploadBoxContainer>
        <UploadBox>
          <div className="name">{data?.data.celeb.name}</div>
          <div className="signature">
            {signatureUrl ? (
              <img src={signatureUrl} alt="uploaded signature" />
            ) : (
              "Signature"
            )}
          </div>
          <div className="button-container">
            <StyledButton
              size="xs"
              color="white"
              label={signatureUrl ? "Change" : "Upload"}
              onClick={handleUploadClick}
              disabled={signFileIsPending}
            />
            <StyledButton size="xs" color="white" label="Create" />
          </div>
        </UploadBox>
      </UploadBoxContainer>

      <div style={{ width: "100%", borderTop: "0.4rem solid var(--black)" }} />

      <StyledButton
        size="md"
        label="Request"
        color="black"
        margin="2rem auto 0"
        onClick={() => onClickRequest()}
        disabled={!signatureUrl || signFilePostIsPending || disabledStatus()}
      />

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept=".png, .jpeg, .jpg" // 허용할 파일 확장자
        style={{ display: "none" }}
      />
    </Container>
  );
};

export default SignatureUpload;
