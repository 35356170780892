import React from "react";
import styled from "styled-components";

const ApplyButton = styled.button`
  width: 17.2rem;
  height: 4.4rem;
  flex-shrink: 0;
  background: var(--gray-900);
  color: var(--gray-050);
  margin: 0 auto;
  display: block;
`;

interface ApplyProps {
  refetch: () => void;
}

const Apply = ({ refetch }: ApplyProps) => {
  return <ApplyButton onClick={refetch}>apply</ApplyButton>;
};

export default Apply;
