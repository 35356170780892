import React from "react";
import styled from "styled-components";
import { ContentBox } from "./ContentBox";
import ImageContainer from "../common/ImageContainer";

const Container = styled.div`
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  > .text {
    ${({ theme }) => theme.font.body2};
    ${({ theme }) => theme.font.regular};
    color: var(--gray-600);
  }
`;

const WaitStatus = () => {
  return (
    <ContentBox title="Content using stock images">
      <Container>
        <ImageContainer width={8} height={8}>
          <img src="/image/no_files.png" alt="no_file_image" />
        </ImageContainer>
        <span className="text">There are no contents to check yet...</span>
      </Container>
    </ContentBox>
  );
};

export default WaitStatus;
