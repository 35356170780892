import axios from "axios";
import { SignupFormData } from "../components/interfaces/auth.interfaces";
import {getEnv} from "./axiosInstance";

export const postSignup = async (data: SignupFormData) => {
  const response = await axios.post(
    `${getEnv()}api/v1/users/signup`,
    data
  );

  return response.data;
};
