import React from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const DashboardContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  background-color: var(--gray-050);
`;

const ContentArea = styled.div`
  background-color: var(--gray-050);
  padding: 8rem;
`;

const Content = styled.div`
  width: 112.4rem;
`;

const Dashboard = () => {
  return (
    <DashboardContainer>
      <Sidebar />
      <ContentArea>
        <Content>
          <Outlet />
        </Content>
      </ContentArea>
    </DashboardContainer>
  );
};

export default Dashboard;
