import React, { useEffect, useState } from "react";
import { ContentBox } from "./ContentBox";
import {
  AdvertiseingProductChild,
  AdvertisingProductImage,
} from "../interfaces/contract.interface";
import styled from "styled-components";
import ImageContainer from "../common/ImageContainer";
import AdvertiseCommentList from "./AdvertiseCommentList";
import { useContentList } from "../../hooks/useContract";
import {
  useAdvertiseProductConfirm,
  useAdvertiseProductReject,
} from "../../hooks/useAdvertiseProduct";
import StyledButton from "../button/StyledButton";
import ContentSlot from "./ContentSlot";
import { useContractStatus } from "../../hooks/useContractStatus";
import { CONTRACT_STATUS_PRESETS } from "../../configs/contractStatus";

const MainView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
  margin: 0 auto;
`;

const CommentContainer = styled.div`
  margin-top: 2rem;
  gap: 2rem;
  border-top: 0.4rem solid var(--black);
  padding-top: 6rem;
`;

const SubView = styled.div`
  position: absolute;
  right: 100%;
  top: 0;
  /*margin-right: 1.2rem;*/
`;

const SlotList = styled.div`
  padding-right: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  height: 42rem;
  overflow-y: scroll;
`;

const ContentConfirmButtonContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 2rem;
  top: 1rem;
  right: 1rem;
  display: flex;
`;

const ContentConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  ${({ theme }) => theme.font.body2};
  ${({ theme }) => theme.font.regular};
  gap: 0.4rem;
  &.reject {
    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/cancel_circle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &.confirm {
    &::before {
      content: "";
      width: 2rem;
      height: 2rem;
      background-image: url("/svg/check_circle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

const checkAllConfirmed = (
  advertisingImgs: AdvertisingProductImage[]
): boolean => {
  if (!advertisingImgs || advertisingImgs.length === 0) return false;

  return advertisingImgs.every((img) => img.isConfirm === true);
};

interface ContentConfirmProps {
  contractId: string;
  subStatus: string | null;
  refetch: () => void;
}

interface ContentConfirmProps {
  contractId: string;
  refetch: () => void;
}

interface SelectedContent {
  advertisingProduct: AdvertisingProductImage;
  selectedChild: AdvertiseingProductChild;
}

const ContentConfirm = ({
  contractId,
  subStatus,
  refetch,
}: ContentConfirmProps) => {
  const { data, isPending, refetch: refetchList } = useContentList(contractId);
  const [selectedContent, setSelectedContent] = useState<SelectedContent>();
  const { confirm } = useAdvertiseProductConfirm({
    onSuccess: () => {
      refetchList();
    },
  });
  const { reject } = useAdvertiseProductReject({
    onSuccess: () => {
      refetchList();
    },
  });
  const { updateStatus } = useContractStatus(contractId, {
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (data?.data.advertisingImgs.length) {
      const firstImage = data.data.advertisingImgs[0];
      if (
        !selectedContent?.advertisingProduct ||
        !data.data.advertisingImgs.find(
          (img) =>
            img.advertisingProductId ===
            selectedContent.advertisingProduct.advertisingProductId
        )
      ) {
        if (firstImage.children.length > 0) {
          setSelectedContent({
            advertisingProduct: firstImage,
            selectedChild: firstImage.children[0],
          });
        }
      }
    }
  }, [data, selectedContent]);

  if (isPending) return <>Loading</>;
  if (!data || !selectedContent) return <></>;

  const handleImageSelect = (
    image: AdvertisingProductImage,
    children: AdvertiseingProductChild
  ) => {
    setSelectedContent({
      advertisingProduct: image,
      selectedChild: children,
    });
  };

  const disabledStatus =
    subStatus === "UPLOAD_SIDE_MODIFY_OR_UPLOAD/CONTENT_SIDE_WAITING_FOR_MODIFY"
      ? true
      : false;

  return (
    <ContentBox title="Content Using Stock Images Confirm">
      <MainView>
        <ImageContainer width={42} height={42}>
          <img src={selectedContent.selectedChild.imgUrl} alt="test" />
        </ImageContainer>
        {selectedContent.advertisingProduct.children[0].imgUrl ===
          selectedContent.selectedChild.imgUrl && (
          <ContentConfirmButtonContainer>
            <ContentConfirmButton
              className="reject"
              onClick={() =>
                reject(selectedContent.advertisingProduct.advertisingProductId)
              }
            >
              Reject
            </ContentConfirmButton>
            <ContentConfirmButton
              className="confirm"
              onClick={() =>
                confirm(selectedContent.advertisingProduct.advertisingProductId)
              }
            >
              Confirm
            </ContentConfirmButton>
          </ContentConfirmButtonContainer>
        )}
        <CommentContainer>
          <AdvertiseCommentList
            advertisingProductId={
              selectedContent.advertisingProduct.advertisingProductId
            }
            commentList={selectedContent.advertisingProduct.comments}
            contractId={selectedContent.advertisingProduct.contractId}
            refetch={refetchList}
          />
        </CommentContainer>
        <SubView>
          <SlotList>
            {data.data.advertisingImgs.map((image) => (
              <ContentSlot
                key={image.advertisingProductId}
                onClickFn={handleImageSelect}
                productImage={image}
              />
            ))}
          </SlotList>
        </SubView>
        <ButtonContainer>
          <StyledButton
            size="sm"
            color="white"
            label="Re-evaluation"
            disabled={disabledStatus}
            onClick={() => updateStatus(CONTRACT_STATUS_PRESETS.UPLAOD_MODIFY)}
          />
          <StyledButton
            size="sm"
            color="black"
            label="Full agreement"
            disabled={
              !checkAllConfirmed(data.data.advertisingImgs) || disabledStatus
            }
            onClick={() => updateStatus(CONTRACT_STATUS_PRESETS.READY_FOR)}
          />
        </ButtonContainer>
      </MainView>
    </ContentBox>
  );
};

export default ContentConfirm;
