import { useState } from "react";
import {
  AdvertiseingProductChild,
  AdvertisingProductImage,
} from "../interfaces/contract.interface";
import styled from "styled-components";
import ImageContainer from "../common/ImageContainer";
import formatDate from "../../utils/utils";

const SlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 12rem;
  > .content {
    width: 12rem;
    cursor: pointer;
    position: relative;
    > .title {
      color: var(--gray-900);
      ${({ theme }) => theme.font.body3};
      ${({ theme }) => theme.font.regular};
      text-align: left;
      padding: 0.4rem 0;
    }
  }
  > .sub-content {
    margin-left: auto;
    width: 8rem;
    height: 8rem;
    cursor: pointer;
  }
`;

const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.font.body4};
  ${({ theme }) => theme.font.regular};
  height: 2rem;
  background-color: var(--white);
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  padding: 0.4rem;
  border-radius: 0.4rem;
  gap: 0.4rem;
  &.reject {
    color: var(--crimson-600);
    &::before {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      background-image: url("/svg/rejected.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
  &.confirm {
    color: var(--blue-500);
    &::before {
      content: "";
      width: 1.6rem;
      height: 1.6rem;
      background-image: url("/svg/confirmed.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
  }
`;

interface SlotProps {
  productImage: AdvertisingProductImage;
  onClickFn: (
    advertisingProduct: AdvertisingProductImage,
    selectedChild: AdvertiseingProductChild
  ) => void;
}

const ContentSlot = ({ productImage, onClickFn }: SlotProps) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!productImage.children.length) return <></>;

  const sortedList = productImage.children.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    return dateB.getTime() - dateA.getTime();
  });
  const currentProduct = sortedList[0];
  const historyProduct = sortedList.slice(1);

  const onClickSlot = (children: AdvertiseingProductChild) => {
    onClickFn(productImage, children);
  };

  const renderLabel = (isConfirm: true | false | null) => {
    switch (isConfirm) {
      case true:
        return <StatusLabel className="confirm">Confirmed</StatusLabel>;
      case false:
        return <StatusLabel className="reject">Rejected</StatusLabel>;
      default:
        <></>;
    }
  };

  return (
    <SlotContainer>
      <div className="content" onClick={() => setIsOpen(!isOpen)}>
        <ImageContainer width={12} height={12} position="static">
          <img
            src={currentProduct.imgUrl}
            alt="current-image"
            onClick={() => onClickSlot(productImage.children[0])}
          />
        </ImageContainer>
        {renderLabel(productImage.isConfirm)}
        <div className="title">{formatDate(productImage.createdAt)}</div>
      </div>
      {isOpen && (
        <>
          {historyProduct.map((history) => (
            <ImageContainer
              key={history.subAdvertisingProductId}
              className="sub-content"
              onClick={() => onClickSlot(history)}
            >
              <img src={history.imgUrl} alt="sub" />
            </ImageContainer>
          ))}
        </>
      )}
    </SlotContainer>
  );
};
export default ContentSlot;
