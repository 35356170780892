import React from "react";
import RegisterInput from "../input/RegisterInput";
import { useForm } from "react-hook-form";
import {
  CreateProjectFormData,
  CreateProjectSuccessResponse,
} from "../interfaces/project.interface";
import { zodResolver } from "@hookform/resolvers/zod";
import RegisterTextArea from "../input/RegisterTextArea";
import Currency from "../contract/Currency";
import Period from "../contract/Period";
import StyledButton from "../button/StyledButton";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { createProject } from "../../api/project";
import { useNavigate } from "react-router-dom";
import { FormContainer, StepDescription } from "../common/FormContainer";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;

const CreateProject = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = useForm<CreateProjectFormData>({
    //resolver: zodResolver(schema),
    defaultValues: {
      title: "",
      description: "",
      currencyType: "USD",
      budget: 0,
      startDate: "",
      endDate: "",
    },
    mode: "onBlur",
    criteriaMode: "all",
  });

  const mutation = useMutation<
    CreateProjectSuccessResponse,
    AxiosError,
    CreateProjectFormData
  >({
    mutationFn: createProject,
    onSuccess: (data) => {
      // navigate("/dashboard/projects/list?type=ongoing");
      navigate(-1);
    },
    onError: (error) => {},
  });

  const onSubmit = (data: CreateProjectFormData) => {
    mutation.mutate(data);
  };

  const dates = watch(["startDate", "endDate"]);
  const currencyType = watch("currencyType");

  return (
    <FormContainer>
      <StepDescription>
        Some of the details entered in the project are reflected in the
        contract. Please double-check the content.
      </StepDescription>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RegisterInput register={register} fieldName="title" label="Title" />
        <RegisterTextArea
          register={register}
          fieldName="description"
          label="Description"
          placeholder="Please enter any additional negotiation details regarding the contract."
        />
        <Currency
          register={register}
          setValue={setValue}
          name="budget"
          currencyType={currencyType}
          errorMessage={{
            amount: errors.budget?.message,
            currencyType: errors.currencyType?.message,
          }}
        />
        <Period
          setValue={setValue}
          label="Period"
          value={{
            startDate: dates[0],
            endDate: dates[1],
          }}
          errorMessage={{
            startDate: errors.startDate?.message,
            endDate: errors.endDate?.message,
          }}
        />
        <StyledButton type="submit" label="Confirm" color="black" size="lg" />
      </Form>
    </FormContainer>
  );
};

export default CreateProject;
