import { useQuery } from "@tanstack/react-query";
import { getProject } from "../api/project";

export const useProject = (id: string | undefined) => {
  const { data, isPending, refetch } = useQuery({
    queryKey: ["getProjectData", id],
    queryFn: () => getProject(id),
    enabled: !!id,
  });

  return {
    data,
    isPending,
    refetch,
  };
};
