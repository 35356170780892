import React from "react";
import {
  CardData,
  CardTitle,
  CountryColorSet,
  GridCard,
  ReportTitle,
} from "./ReportSection";
import styled from "styled-components";
import { CelebData } from "../interfaces/celeb.interfaces";
import AudienceGeoChart from "./AudienceGeoChart";
import { formatNumberWithCommas } from "../../utils/utils";
import HelpMessage from "../icon/HelpMessage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15.8rem;
`;
const SearchTrendGridContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-rows: 30.2rem;
  grid-template-columns: repeat(3, 41.6rem);
  gap: 2rem;
`;

const CountryList = styled.ul`
  width: 100%;
  height: 100%;
  align-content: center;
  margin-bottom: auto;
`;

interface CountryProps {
  $hoverColor: string;
  $color: string;
}

const Country = styled.li<CountryProps>`
  border: 0.1rem solid var(--gray-300);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.8rem;
  padding: 0.8rem 2rem;
  height: 4rem;
  color: var(--gray-900);
  .country-name {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    line-height: 4rem;
    &::before {
      content: "";
      width: 0.5rem;
      height: 0.5rem;
      margin-right: 0.8rem;
      border-radius: 50%;
      border: 0.1rem solid black;
      display: inline-block;
    }
  }
  .country-score {
    ${({ theme }) => theme.font.body1};
    ${({ theme }) => theme.font.regular};
    border: 0.1rem solid var(--gray-300);
    border-radius: 1.2rem;
    padding: 0.2rem 1rem;
  }
  &:hover {
    background-color: ${(props) => props.$hoverColor};
    .country-score {
      background-color: ${(props) => props.$color};
      color: var(--white);
    }
  }
`;

const CountryChartList = styled.div`
  display: flex;
  gap: 2.2rem;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  align-content: center;
`;

interface SearchReportProps {
  celebData: CelebData;
}

const HoverColorSet = ["#D4F3EB", "#E9E3FB", "#FFF3BC", "#FFD4F7", "#F8E6DD"];

const SearchTrendReport = ({ celebData }: SearchReportProps) => {
  if (
    celebData.searchTrend &&
    celebData.searchTrend.totalVolume !== undefined &&
    Array.isArray(celebData.searchTrend.topSearchPercent) &&
    celebData.searchTrend.topSearchPercent.length > 0
  ) {
    return (
      <Container>
        <ReportTitle>Search Trend</ReportTitle>
        <SearchTrendGridContainer>
          <GridCard>
            <CardTitle>
              Total search volume
              <HelpMessage>
                monthly average clickstream search volume
              </HelpMessage>
            </CardTitle>
            <CardData>
              {formatNumberWithCommas(celebData.searchTrend.totalVolume)}
            </CardData>
          </GridCard>
          <GridCard>
            <CardTitle>Popular countries by search volume</CardTitle>
            <CountryList>
              {celebData.searchTrend.topSearchPercent.map((data, index) => {
                return (
                  <Country
                    $hoverColor={HoverColorSet[index]}
                    $color={CountryColorSet[index]}
                  >
                    <span className="country-name">{data.countryName}</span>
                    <span className="country-score">{data.searchVolume}</span>
                  </Country>
                );
              })}
            </CountryList>
          </GridCard>
          <GridCard>
            <CardTitle>Popular countries by search percentage</CardTitle>
            <CountryChartList>
              {celebData.searchTrend.topSearchPercent.map((data, index) => {
                return (
                  <AudienceGeoChart
                    key={celebData.celebInfo.id + data.countryIsoCode}
                    name={data.countryIsoCode}
                    value={data.percentage}
                    color={CountryColorSet[index]}
                  />
                );
              })}
            </CountryChartList>
          </GridCard>
        </SearchTrendGridContainer>
      </Container>
    );
  }
  return <></>;
};

export default SearchTrendReport;
